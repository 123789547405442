import React from 'react';
import {withRouter} from 'react-router'
// import pjson from "../package.json";
import moment from "moment-timezone";
import { generateToken } from "./utils/layoutUtils";
import localStorage from "react-secure-storage";

import { Card, Spinner, Container, Row, Col } from 'react-bootstrap';


class Auth extends React.Component {
    state={
        auth:false,
        code:null,
        
    }

    async componentDidMount() {
        // const code = (this.props.location.search.match(/code=([^&]+)/) || [])[1];
        // console.log(code)
        // this.setState({code:code})
        console.log(this.props)
        const search=this.props.location.search
        console.log(search)
        let searchParams=new URLSearchParams(search)
        console.log(searchParams)
        let redirectUrl = process.env.REACT_APP_PROXY_URL
        redirectUrl = redirectUrl.slice(0,-4)
        console.log(redirectUrl)
        if (!this.props.location.search || moment().isAfter(localStorage.getItem('loginExpiration'))) {
            localStorage.clear()
            window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=none')
        } else if(localStorage.getItem('auth')&&localStorage.getItem('auth')==='true' && moment().isBefore(localStorage.getItem('loginExpiration'))) {
            window.location.assign('/dashboard')
        } else if (searchParams.get('error') ) { //&& (searchParams.get('error')==='login_required' || searchParams.get('error')==='invalid_authentication_state')
            // https://dw.optum.com/auth?error=invalid_authentication_state&error_description=Invalid%20Authentication%20state
            // https://dwstg.optum.com/auth?error=interaction_required&error_description=User%20interaction%20required
            // below commented because only used for diagnostics. 8/24
            // if (searchParams.get('error')==='interaction_required') {
            //     window.alert('Emulation Support mode not yet implemented/supported.')
            // }
            console.log(searchParams.get('error_description'))
            console.log(searchParams)
            localStorage.clear()
            window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
        } else {
            const code=searchParams.get('code')
            localStorage.clear()
            // this.setState({code:code, auth:true})
            // let token = await generateToken();
            // localStorage.setItem("token", token);
            fetch(`${process.env.REACT_APP_PROXY_URL}/getUserFromCode?code=${code}`)
            .then(res=>res.json())
            .then(async resj=>{
                try {
                    console.log(JSON.stringify(resj,null,'\t'))
                  
                    if(resj.status == 400 || resj.status == 401 || resj.name === 'Error' || (/error/i).test(resj.message)) {
                        localStorage.clear()
                        // put msgBox here
                        alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
                        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                        return;
                    }

                    if(!resj.result) {
                        localStorage.clear()
                        // put msgBox here
                        alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
                        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                        return;
                    }

                    if(!resj.token) {
                        alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
                        localStorage.clear()
                        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                        return;
                    }

                    localStorage.setItem("token", resj.token)

                    let underMaintenance = await this.getMaintenanceCheck()
                    console.log(underMaintenance)
                
                    if(underMaintenance === 'failed') {
                        this.props.showError()
                        return;
                    }

                    if(underMaintenance) {
                        this.props.showMaintenance()
                        return;
                    }
                    // this.postRemoveUserOk()
                    this.props.handleAuth(resj.result)
                } catch (e) {
                    console.log(e)
                    alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
                    localStorage.clear()
                    window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                }
                // relax all these as it'll be on jadeProxy
                // let userData = resj
                // let tinsCount = userData.tinsCount
                // if(tinsCount > 0) {
                //    userData.clients = ['UHC']
                // } else {
                //     userData.clients = []
                // }
                // console.log(userData)
                // fetch(`${process.env.REACT_APP_API_URL}/putEnrichUserJson`, {
                //     method: "put",
                //     headers: new Headers({ "Content-Type": "application/json" }),
                //     body: JSON.stringify(userData)
                // })
                // .then(res2=>res2.json())
                // .then(resj2=>{
                //     console.log(JSON.stringify(resj2,null,'\t'))
                //     let enrichedRes = resj2.result
                //     console.log(enrichedRes)
                //     if(enrichedRes === undefined) {
                //         localStorage.clear()
                //         window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                //        return;
                //     }
                //     this.props.handleAuth(enrichedRes)
                //     // // this.setState({userDetails:resj})
                // })
                // .catch((error) => {
                //     console.log(error)
                //     // window.open(redirectUrl, '_blank');
                // })
            })
            .catch((error) => {
                console.log(error)
                alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
                localStorage.clear()
                window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
                // this.props.showError()
            })
        }
    }

    getMaintenanceCheck = async() => {
        try {
        let currentTime = moment(new Date()).tz('America/Los_Angeles').format("YYYY-MM-DD[T]HH:mm:ss");
        console.log(currentTime)
        let res = await fetch(process.env.REACT_APP_API_URL +  "/getMaintenanceCheck?dateTime=" + currentTime);
        let resj = await res.json();
        if (resj.rows) {
          let forReturn = [...resj.rows];
          if(forReturn.length > 0) {
          let broadcast = forReturn[0];
          console.log(broadcast)
          return true
          } 

         return false
          
        }
        return false
    } catch(e){
        return 'failed'
        }
    }

    render() { 
        // let userDetails=JSON.stringify(this.state.userDetails,null,'\t')
        // if (this.state.oops) {
        //     return <Oops></Oops>
        // } else if (this.state.oops2) {
        //     return <Oops2></Oops2>
        // } 
        // // else if(this.state.showTOU){
        // //     return <TOU />
        // // } 
        // else  {
            return (
                    <Container fluid className='text-center fluid justify-content-center' style={{width:'1920px'}}>
                        <Row><Col>&emsp;</Col></Row>
                        <Row>
                            <Col lg={4}></Col>
                            <Col lg={4}>
                                <Card className='justify-content-center' style={{width:'480px'}}>
                                    {/* <Card.Img variant="top" src={process.env.PUBLIC_URL + "/UHCCommunity.png"} height='80px' className='text-center'/> */}
                                    <Card.Header className='text-center'>
                                    <Spinner animation="border" variant="primary" />
                                    <Spinner animation="border" variant="secondary" />
                                    <Spinner animation="border" variant="warning" />
                                    <h5>Loading...</h5>
                                    </Card.Header>
                                    <Card.Body>
                                    <Card.Text className='text-center'>
                                    Please wait while the system authorizes you for PCR use...
                                    </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}></Col>
                        </Row>
                    </Container>
                    );
        // }
    }
}
 
export default withRouter(Auth);


