import localStorage from "react-secure-storage";
const _ = require("underscore");
const CryptoJS = require("crypto-js");
const pjson = require("../../package.json");
const moment = require("moment-timezone");
let keySize = 256;
let ivSize = 128;
let iterations = 100;

const encrypt = (msg, pass) => {
  let salt = CryptoJS.lib.WordArray.random(128 / 8);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let iv = CryptoJS.lib.WordArray.random(ivSize / 8);

  let encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256,
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  let transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
};

const decrypt = (transitmessage, pass) => {
  let salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  let iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
  let encrypted = transitmessage.substring(64);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256,
  });
  return decrypted;
};

const generateToken = async () => {
  let date = moment().utc().format();
  try {
    let obj = { appName: pjson.name, timeStamp: date, u: localStorage.getItem('u') ?? '' };
    let payload = JSON.stringify(obj);
    let secret = process.env.REACT_APP_DMS_APP_SECRET;

    let encrypted = encrypt(payload, secret);
    // let decrypted = decrypt(encrypted, process.env.REACT_APP_DMS_APP_SECRET);
    // console.log("Encrypted: " + encrypted);
    // console.log("Decrypted: " + decrypted.toString(CryptoJS.enc.Utf8));
    return encrypted;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
  }
  return "";
};

const formatDate = (dateString) => {
  if (dateString) {
    return moment(dateString).tz("America/Chicago").format("MM/DD/YYYY");
  } else {
    return "";
  }
};

const formatDateUTC = (dateString) => {
  if (dateString) {
    return moment(dateString).utc().format("MM/DD/YYYY");
  } else {
    return "";
  }
};

const formatDateTime = (dateTimeString) => {
  if (dateTimeString) {
    return moment(dateTimeString)
      .tz("America/Chicago")
      .format("MM/DD/YYYY h:mm:ss A");
  } else {
    return "";
  }
};

const formatTime = (dateTimeString) => {
  if (dateTimeString) {
    return moment(dateTimeString).tz("America/Chicago").format("h:mm:ss A");
  } else {
    return "";
  }
};

const formatPercentage = (num) => {
  return Number(num).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
};

const colorAlpha = 0.4;

const blankIt = (str) => {
  return str.replace(/_/g, " ");
};

const capitalize = (str) => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//For changing background color based on Data in column
const validColorCoding = (params) => {
  if (params.value === "Valid") {
    return { backgroundColor: `rgba(111,193,177,${colorAlpha})` };
  } else if (params.value === "NotValid") {
    return { backgroundColor: `rgba(209,63,68,${colorAlpha})` };
  } else if (params.value === "Maybe") {
    return { backgroundColor: `rgba(242,180,17,${colorAlpha})` };
  } else {
    // if (params.value==='Maybe')
    return { backgroundColor: `rgba(242,180,17,0.2)` };
  }
};

const passFailColor = (params) => {
  switch (params.value) {
    case "Pass":
      return {
        backgroundColor: `rgba(111,193,177,${colorAlpha})`,
        overflow: "visible",
      };
    // break;
    case "Fail":
      return {
        backgroundColor: `rgba(209,63,68,${colorAlpha})`,
        overflow: "visible",
      };
    // break;
    case null:
      return {
        backgroundColor: `rgba(242,180,17,${colorAlpha})`,
        overflow: "visible",
      };
    // break;
    default:
      break;
  }
};

const colSequencer = (colDefArray, desiredArrangementArray) => {
  let forReturn = [];
  desiredArrangementArray.forEach((element) => {
    if (_.findWhere(colDefArray, { field: element })) {
      forReturn.push(_.findWhere(colDefArray, { field: element }));
    }
  });
  return forReturn;
};

const columnsFactory = (obj) => {
  // let colorAlpha=0.4
  let columnDefs = Object.keys(obj).map((k, i) => {
    let colDef = {
      field: k,
      headerName: blankIt(capitalize(k)),
      tooltipField: k,
      headerTooltip: k,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: { excelMode: "mac", newRowsAction: "keep" },
      width: 80,
    };
    //big switch here for mapping
    switch (k.toLowerCase()) {
      case "project_id":
      case "projectid":
        colDef = {
          ...colDef,
          headerName: "Project Name",
          headerTooltip: "Project Name",
          width: 140,
        };
        break;
      case "packageid":
        colDef = {
          ...colDef,
          headerName: "Package ID",
          headerTooltip: "Package ID",
          width: 90,
        };
        break;
      case "packagetype":
        colDef = {
          ...colDef,
          headerName: "Package Type",
          headerTooltip: "Package Type",
          width: 90,
        };
        break;
      case "provider.providerid":
      case "providerid":
        colDef = {
          ...colDef,
          headerName: "Provider ID",
          headerTooltip: "Provider ID",
          width: 155,
        };
        break;
      case "providertin":
        colDef = {
          ...colDef,
          headerName: "Provider TIN",
          headerTooltip: "Provider TIN",
          width: 100,
        };
        break;
      case "prov_firstname":
        colDef = {
          ...colDef,
          headerName: "Provider First Name",
          headerTooltip: "Provider First Name",
          width: 100,
        };
        break;
      case "prov_lastname":
        colDef = {
          ...colDef,
          headerName: "Provider Last Name",
          headerTooltip: "Provider Last Name",
          width: 100,
        };
        break;
      case "alt1_firstname":
        colDef = {
          ...colDef,
          headerName: "Alt Contact First Name",
          headerTooltip: "Alt Contact First Name",
          width: 100,
        };
        break;
      case "alt1_lastname":
        colDef = {
          ...colDef,
          headerName: "Alt1 Contact Last Name",
          headerTooltip: "Alt1 Contact Last Name",
          width: 100,
        };
        break;
      case "alt2_firstname":
        colDef = {
          ...colDef,
          headerName: "Alt2 Contact First Name",
          headerTooltip: "Alt2 Contact First Name",
          width: 100,
        };
        break;
      case "alt2_lastname":
        colDef = {
          ...colDef,
          headerName: "Alt2 Contact Last Name",
          headerTooltip: "Alt2 Contact Last Name",
          width: 100,
        };
        break;
      case "groupid":
        colDef = {
          ...colDef,
          headerName: "Group ID",
          headerTooltip: "Group ID",
          width: 100,
        };
        break;
      case "groupname":
        colDef = {
          ...colDef,
          headerName: "Group Name",
          headerTooltip: "Group Name",
          width: 170,
        };
        break;
      case "status":
        colDef = { ...colDef, headerName: "Status", headerTooltip: "Status" };
        break;
      case "datestatus":
        colDef = {
          ...colDef,
          headerName: "Date Status",
          headerTooltip: "Date Status",
          width: 90,
          valueFormatter: (params) => formatDateUTC(params.value),
        };
        break;
      // 'page_num','review_status','datereviewstatus', 'modified'
      case "page_num":
        colDef = {
          ...colDef,
          headerName: "Page",
          headerTooltip: "Page",
          width: 70,
        };
        break;
      case "modified":
        colDef = {
          ...colDef,
          headerName: "Modified",
          headerTooltip: "Modified",
          width: 95,
        };
        break;
      case "review_status":
        colDef = {
          ...colDef,
          headerName: "Review Status",
          headerTooltip: "Review Status",
          width: 85,
        };
        break;
      case "datereviewstatus":
        colDef = {
          ...colDef,
          headerName: "Date Status",
          headerTooltip: "Date Status",
          width: 90,
          valueFormatter: (params) => formatDateUTC(params.value),
        };
        break;
      // 'packageid', 'packagetype','action','dateaction','user', 'notes'
      case "action":
        colDef = {
          ...colDef,
          headerName: "Action",
          headerTooltip: "Action",
          width: 125,
        };
        break;
      case "user":
        colDef = {
          ...colDef,
          headerName: "User",
          headerTooltip: "User",
          width: 90,
        };
        break;
      case "notes":
        colDef = {
          ...colDef,
          headerName: "Notes",
          headerTooltip: "Notes",
          width: 500,
        };
        break;
      case "dateaction":
        colDef = {
          ...colDef,
          headerName: "Date Action",
          headerTooltip: "Date Action",
          width: 90,
          valueFormatter: (params) => formatDateUTC(params.value),
        };
        break;
      case "mrmprojectid":
        colDef = {
          ...colDef,
          headerName: "MRM Project ID",
          headerTooltip: "MRM Project ID",
          width: 75,
        };
        break;
      case "sub_project_id":
        colDef = {
          ...colDef,
          headerName: "Sub Project ID",
          headerTooltip: "Sub Project ID",
          width: 75,
        };
        break;
      case "sub_project_name":
        colDef = {
          ...colDef,
          headerName: "Sub Project Name",
          headerTooltip: "Sub Project Name",
          width: 120,
        };
        break;
      case "audityear":
        colDef = {
          ...colDef,
          headerName: "Audit Year",
          headerTooltip: "Audit Year",
          width: 60,
        };
        break;
      case "audittype":
        colDef = {
          ...colDef,
          headerName: "Audit Type",
          headerTooltip: "Audit Type",
          width: 50,
        };
        break;
      case "projectstate":
        colDef = {
          ...colDef,
          headerName: "Project State",
          headerTooltip: "Project State",
          width: 50,
        };
        break;
      case "member_count":
        colDef = {
          ...colDef,
          headerName: "Members",
          headerTooltip: "Members",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "hcc_count":
        colDef = {
          ...colDef,
          headerName: "HCCs",
          headerTooltip: "HCCs",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "medical_records_requested":
        colDef = {
          ...colDef,
          headerName: "Requested",
          headerTooltip: "Requested",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "medical_records_received":
        colDef = {
          ...colDef,
          headerName: "Received",
          headerTooltip: "Received",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "medical_records_cancelled":
        colDef = {
          ...colDef,
          headerName: "Cancelled",
          headerTooltip: "Cancelled",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "medical_records_pnp_cna":
        colDef = {
          ...colDef,
          headerName: "PNP/CNA",
          headerTooltip: "PNP/CNA",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "medical_records_open":
        colDef = {
          ...colDef,
          headerName: "Open",
          headerTooltip: "Medical Records Open",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "retrievalrate":
        colDef = {
          ...colDef,
          headerName: "Retrieval Rate",
          headerTooltip: "Retrieval Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(232,119,34,${colorAlpha})` },
        };
        break;
      case "medical_records_scheduled":
        colDef = {
          ...colDef,
          headerName: "Scheduled",
          headerTooltip: "Scheduled",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "coding_completed":
        colDef = {
          ...colDef,
          headerName: "Coding Completed",
          headerTooltip: "Coding Completed",
          width: 50,
        };
        break;
      case "valid_hccs":
        colDef = {
          ...colDef,
          headerName: "Valid HCCs",
          headerTooltip: "Valid HCCs",
          filter: "agNumberColumnFilter",
          width: 50,
          cellStyle: { backgroundColor: `rgba(111,193,177,${colorAlpha})` },
        };
        break;
      case "non_valid_hccs":
        colDef = {
          ...colDef,
          headerName: "Non Valid HCCs",
          headerTooltip: "Non Valid HCCs",
          width: 50,
          filter: "agNumberColumnFilter",
          cellStyle: { backgroundColor: `rgba(209,63,68,${colorAlpha})` },
        };
        break;
      case "validates_to_higher_hcc":
        colDef = {
          ...colDef,
          headerName: "Validates to Higher HCC",
          headerTooltip: "Validates to Higher HCC",
          filter: "agNumberColumnFilter",
          width: 50,
          cellStyle: { backgroundColor: `rgba(111,193,177,${colorAlpha})` },
        };
        break;
      case "may_validate":
        colDef = {
          ...colDef,
          headerName: "May Validate",
          headerTooltip: "May Validate",
          filter: "agNumberColumnFilter",
          width: 50,
          cellStyle: { backgroundColor: `rgba(242,180,17,${colorAlpha})` },
        };
        break;
      case "validates_to_a_lower_hcc":
        colDef = {
          ...colDef,
          headerName: "Validates to Lower HCC",
          headerTooltip: "Validates to Lower HCC",
          filter: "agNumberColumnFilter",
          width: 50,
          cellStyle: { backgroundColor: `rgba(209,63,68,${colorAlpha})` },
        };
        break;
      case "hccs_pending_validation_results":
        colDef = {
          ...colDef,
          headerName: "HCCs Pending Results",
          headerTooltip: "HCCs Pending Results",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "validation_rate":
        colDef = {
          ...colDef,
          headerName: "Validation Rate",
          headerTooltip: "Validation Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(194,86,8,${colorAlpha})` },
        };
        break;
      case "additional_unreported_hccs":
        colDef = {
          ...colDef,
          headerName: "Additional Unreported HCCs",
          headerTooltip: "Additional Unreported HCCs",
          width: 60,
        };
        break;
      case "total_attestations":
        colDef = {
          ...colDef,
          headerName: "Total ATT",
          headerTooltip: "Total ATT",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "total_attestations_cancelled":
        colDef = {
          ...colDef,
          headerName: "Total ATT Cancelled",
          headerTooltip: "Total ATT Cancelled",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_attestations_retrieved":
        colDef = {
          ...colDef,
          headerName: "Total ATT Retrieved",
          headerTooltip: "Total ATT Retrieved",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_attestations_outstanding":
        colDef = {
          ...colDef,
          headerName: "Total ATT Outstanding",
          headerTooltip: "Total ATT Outstanding",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "att_retrieval_rate":
        colDef = {
          ...colDef,
          headerName: "ATT Retrieval Rate",
          headerTooltip: "ATT Retrieval Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(232,119,34,${colorAlpha})` },
        };
        break;
      case "total_gt_attestations":
        colDef = {
          ...colDef,
          headerName: "Total GT ATT",
          headerTooltip: "Total GT ATT",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "total_gt_attestations_resolved_by_others":
        colDef = {
          ...colDef,
          headerName: "Total GT ATT Resolved",
          headerTooltip: "Total GT ATT Resolved",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_gt_attestations_retrieved":
        colDef = {
          ...colDef,
          headerName: "Total GT ATT Retrieved",
          headerTooltip: "Total GT ATT Retrieved",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_gt_attestations_oustanding":
        colDef = {
          ...colDef,
          headerName: "Total GT ATT Outstanding",
          headerTooltip: "Total GT ATT Outstanding",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "att_gt_retrieval_rate":
        colDef = {
          ...colDef,
          headerName: "ATT GT Retrieval Rate",
          headerTooltip: "ATT GT Retrieval Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(232,119,34,${colorAlpha})` },
        };
        break;
      case "gt_hcc_count":
        colDef = {
          ...colDef,
          headerName: "GT HCC Count",
          headerTooltip: "GT HCC Count",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "gt_hcc_resolved":
        colDef = {
          ...colDef,
          headerName: "GT HCC Resolved",
          headerTooltip: "GT HCC Resolved",
          width: 50,
        };
        break;
      case "gt_hcc_outstanding":
        colDef = {
          ...colDef,
          headerName: "GT HCC Outstanding",
          headerTooltip: "GT HCC Outstanding",
          width: 60,
        };
        break;
      case "total_hcc_value":
        colDef = {
          ...colDef,
          headerName: "Total HCC Value",
          headerTooltip: "Total HCC Value",
          width: 75,
          cellStyle: { textAlign: "right" },
        };
        break;
      case "total_hcc_extrapolated_value":
        colDef = {
          ...colDef,
          headerName: "Total HCC Extrapolated Value",
          headerTooltip: "Total HCC Extrapolated Value",
          width: 60,
        };
        break;
      case "total_sub_order_count":
        colDef = {
          ...colDef,
          headerName: "Total Subs",
          headerTooltip: "Total Subs",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "total_obmr_received":
        colDef = {
          ...colDef,
          headerName: "Total OBMR Received",
          headerTooltip: "Total OBMR Received",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_submitted_needs_review":
        colDef = {
          ...colDef,
          headerName: "Total Subs Needing Review",
          headerTooltip: "Total Subs Needing Review",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_ready_to_submit":
        colDef = {
          ...colDef,
          headerName: "Total Ready to Submit",
          headerTooltip: "Total Ready to Submit",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "total_submitted":
        colDef = {
          ...colDef,
          headerName: "Total Submitted",
          headerTooltip: "Total Submitted",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "percent_total_submitted":
        colDef = {
          ...colDef,
          headerName: "Percent Total Submitted",
          headerTooltip: "Percent Total Submitted",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(66,44,136,${colorAlpha})` },
        };
        break;
      case "hcc_with_sub_orders":
        colDef = {
          ...colDef,
          headerName: "HCCs with Subs",
          headerTooltip: "HCCs with Subs",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "hcc_obmr_received":
        colDef = {
          ...colDef,
          headerName: "HCC OBMR Received",
          headerTooltip: "HCC OBMR Received",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "hcc_submitted_needs_review":
        colDef = {
          ...colDef,
          headerName: "HCC Subs Needing Review",
          headerTooltip: "HCC Subs Needing Review",
          filter: "agNumberColumnFilter",
          width: 70,
        };
        break;
      case "hcc_ready_to_submit":
        colDef = {
          ...colDef,
          headerName: "HCCs Ready to Submit",
          headerTooltip: "HCCs Ready to Submit",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "hcc_submitted":
        colDef = {
          ...colDef,
          headerName: "HCCs Submitted",
          headerTooltip: "HCCs Submitted",
          filter: "agNumberColumnFilter",
          width: 50,
        };
        break;
      case "percent_hccs_submitted":
        colDef = {
          ...colDef,
          headerName: "Percent HCCs Submitted",
          headerTooltip: "Percent HCCs Submitted",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
          cellStyle: { backgroundColor: `rgba(66,44,136,${colorAlpha})` },
        };
        break;
      case "pm_assigned":
        colDef = {
          ...colDef,
          headerName: "PM Assigned",
          headerTooltip: "PM Assigned",
          width: 120,
        };
        break;
      case "assigned":
        colDef = {
          ...colDef,
          headerName: "Assigned",
          headerTooltip: "Assigned",
          width: 90,
        };
        break;
      case "memberid":
        colDef = {
          ...colDef,
          headerName: "Member ID",
          headerTooltip: "Member ID",
          width: 95,
        };
        break;
      case "hicn":
        colDef = {
          ...colDef,
          headerName: "HICN",
          headerTooltip: "HICN",
          width: 100,
        };
        break;
      case "lastname":
        colDef = {
          ...colDef,
          headerName: "Last Name",
          headerTooltip: "Last Name",
          width: 85,
        };
        break;
      case "firstname":
        colDef = {
          ...colDef,
          headerName: "First Name",
          headerTooltip: "First Name",
          width: 85,
        };
        break;
      case "memberdob":
        colDef = {
          ...colDef,
          headerName: "DOB",
          headerTooltip: "Member DOB",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "dob":
        colDef = {
          ...colDef,
          headerName: "DOB",
          headerTooltip: "DOB",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "fromdos":
        colDef = {
          ...colDef,
          headerName: "From DOS",
          headerTooltip: "From DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 95,
        };
        break; //valueFormatter:(params) => formatDate(params.value),
      case "todos":
        colDef = {
          ...colDef,
          headerName: "To DOS",
          headerTooltip: "To DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 95,
        };
        break;
      case "service_begin_date":
        colDef = {
          ...colDef,
          headerName: "From DOS",
          headerTooltip: "From DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break; //valueFormatter:(params) => formatDate(params.value),
      case "service_end_date":
        colDef = {
          ...colDef,
          headerName: "To DOS",
          headerTooltip: "To DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "start_date":
        colDef = {
          ...colDef,
          headerName: "Start Date",
          headerTooltip: "Start Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "end_date":
        colDef = {
          ...colDef,
          headerName: "End Date",
          headerTooltip: "End Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "providernpi":
        colDef = {
          ...colDef,
          headerName: "Provider NPI",
          headerTooltip: "Provider NPI",
          width: 95,
        };
        break;
      case "providerlastname":
        colDef = {
          ...colDef,
          headerName: "Provider Last Name",
          headerTooltip: "Provider Last Name",
          width: 160,
        };
        break;
      case "providerfirstname":
        colDef = {
          ...colDef,
          headerName: "Provider First Name",
          headerTooltip: "Provider First Name",
          width: 90,
        };
        break;
      case "providerkey":
        colDef = {
          ...colDef,
          headerName: "Provider Key",
          headerTooltip: "Provider Key",
          width: 80,
        };
        break;
      case "memberlastname":
        colDef = {
          ...colDef,
          headerName: "Member Last Name",
          headerTooltip: "Member Last Name",
          width: 90,
        };
        break;
      case "memberfirstname":
        colDef = {
          ...colDef,
          headerName: "Member First Name",
          headerTooltip: "Member First Name",
          width: 90,
        };
        break;
      case "membermiddleinitial":
        colDef = {
          ...colDef,
          headerName: "Member Middle Initial",
          headerTooltip: "Member Middle Initial",
          width: 80,
        };
        break;
      case "memberpbp":
        colDef = {
          ...colDef,
          headerName: "Member PBP",
          headerTooltip: "Member PBP",
          width: 80,
        };
        break;
      case "membercontract":
        colDef = {
          ...colDef,
          headerName: "Member Contract",
          headerTooltip: "Member Contract",
          width: 60,
        };
        break;
      case "memberlob":
        colDef = {
          ...colDef,
          headerName: "Member LOB",
          headerTooltip: "Member LOB",
          width: 80,
        };
        break;
      case "icd_dx_cd":
        colDef = {
          ...colDef,
          headerName: "ICD DX Code",
          headerTooltip: "ICD DX Code",
          width: 80,
        };
        break;
      case "icd_ver_flag":
        colDef = {
          ...colDef,
          headerName: "ICD Ver Flag",
          headerTooltip: "ICD Ver Flag",
          width: 80,
        };
        break;
      case "siteid":
        colDef = {
          ...colDef,
          headerName: "Site_ID",
          headerTooltip: "Site ID",
          width: 80,
        };
        break;
      case "address1":
        colDef = {
          ...colDef,
          headerName: "Address",
          headerTooltip: "Address 1",
          width: 160,
        };
        break;
      case "address2":
        colDef = {
          ...colDef,
          headerName: "Address 2",
          headerTooltip: "Address 2",
          width: 110,
        };
        break;
      case "city":
        colDef = {
          ...colDef,
          headerName: "City",
          headerTooltip: "City",
          width: 120,
        };
        break;
      case "state":
        colDef = {
          ...colDef,
          headerName: "State",
          headerTooltip: "State",
          width: 75,
        };
        break;
      case "providertype":
        colDef = {
          ...colDef,
          headerName: "Provider Type",
          headerTooltip: "Provider Type",
          width: 80,
        };
        break;
      case "providergroupid":
        colDef = {
          ...colDef,
          headerName: "Provider Group ID",
          headerTooltip: "Provider Group ID",
          width: 80,
        };
        break;
      case "provideraddress1":
        colDef = {
          ...colDef,
          headerName: "Provider Address",
          headerTooltip: "Provider Address",
          width: 160,
        };
        break;
      case "providercity":
        colDef = {
          ...colDef,
          headerName: "Provider City",
          headerTooltip: "Provider City",
          width: 110,
        };
        break;
      case "providerstate":
        colDef = {
          ...colDef,
          headerName: "Provider State",
          headerTooltip: "Provider State",
          width: 50,
        };
        break;
      case "providerphoneformatted":
        colDef = {
          ...colDef,
          headerName: "Phone",
          headerTooltip: "Provider Phone",
          width: 120,
        };
        break;
      case "providergroupname":
        colDef = {
          ...colDef,
          headerName: "GroupName",
          headerTooltip: "Provider Group Name",
          width: 200,
        };
        break;
      case "email":
        colDef = {
          ...colDef,
          headerName: "Email",
          headerTooltip: "Email",
          width: 90,
        };
        break;
      case "outreachgroupid":
        colDef = {
          ...colDef,
          headerName: "Outreach ID",
          headerTooltip: "Outreach ID",
          width: 85,
        };
        break;
      case "chartfilepath":
        colDef = {
          ...colDef,
          headerName: "Chart filepath",
          headerTooltip: false,
          hidden: true,
        };
        break;
      case "locid":
        colDef = {
          ...colDef,
          headerName: "Location ID",
          headerTooltip: "Location ID",
          width: 90,
        };
        break;
      case "chartprovidertype":
        colDef = {
          ...colDef,
          headerName: "Chart Type",
          headerTooltip: "Chart Type",
          width: 90,
        };
        break;
      case "chartscoregroup":
        colDef = {
          ...colDef,
          headerName: "Priority",
          headerTooltip: "Priority",
          width: 70,
        };
        break;
      case "lastcscoid":
        colDef = {
          ...colDef,
          headerName: "Last CSCO ID",
          headerTooltip: "Last CSCO ID",
          width: 80,
        };
        break;
      case "contractid":
        colDef = {
          ...colDef,
          headerName: "Contract ID",
          headerTooltip: "Contract ID",
          width: 60,
        };
        break;
      case "contract":
        colDef = {
          ...colDef,
          headerName: "Contract",
          headerTooltip: "Contract",
          width: 70,
        };
        break;
      case "parentgroup":
        colDef = {
          ...colDef,
          headerName: "Parent Group",
          headerTooltip: "Parent Group",
          width: 80,
        };
        break;
      case "fullname":
        colDef = {
          ...colDef,
          headerName: "Member",
          headerTooltip: "Member",
          width: 100,
        };
        break;
      case "hcc":
        colDef = {
          ...colDef,
          headerName: "HCC",
          headerTooltip: "HCC",
          width: 70,
        };
        break;
      case "chartid":
        colDef = {
          ...colDef,
          headerName: "Chart ID",
          headerTooltip: "Chart ID",
          width: 90,
        };
        break;
      case "chartreceivedate":
        colDef = {
          ...colDef,
          headerName: "Chart Rec'v",
          headerTooltip: "Chart Received Date",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "chartstatus":
        colDef = {
          ...colDef,
          headerName: "Chart Status",
          headerTooltip: "Chart Status",
          width: 110,
        };
        break;
      case "outreachdate1":
        colDef = {
          ...colDef,
          headerName: "Outreach Date 1",
          headerTooltip: "Outreach Date 1",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "outreachdate2":
        colDef = {
          ...colDef,
          headerName: "Outreach Date 2",
          headerTooltip: "Outreach Date 2",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "outreachdate3":
        colDef = {
          ...colDef,
          headerName: "Outreach Date 3",
          headerTooltip: "Outreach Date 3",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      // case 'callbackdate': colDef={...colDef, headerName: "Callback Date", width:100}; break;
      case "asm_source":
        colDef = {
          ...colDef,
          headerName: "ASM Source",
          headerTooltip: "ASM Source",
          width: 80,
        };
        break;
      case "hcc_value":
        colDef = {
          ...colDef,
          headerName: "HCC Value",
          headerTooltip: "HCC Value",
          width: 65,
        };
        break;
      case "capitation_percent":
        colDef = {
          ...colDef,
          headerName: "Capitation Percent",
          headerTooltip: "Capitation Percent",
          width: 70,
        };
        break;
      case "hccv12":
        colDef = {
          ...colDef,
          headerName: "HCCV12",
          headerTooltip: "HCCV12",
          width: 50,
        };
        break;
      case "hccv12_validation":
        colDef = {
          ...colDef,
          headerName: "HCCV12 Validation",
          headerTooltip: "HCCV12 Validation",
          width: 70,
          cellStyle: validColorCoding,
        };
        break;
      case "callbackdate":
        colDef = {
          ...colDef,
          headerName: "Callback Date",
          headerTooltip: "Callback Date",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break; //valueFormatter:(params) => formatDateUTC(params.value),
      case "attstatus":
        colDef = { ...colDef, headerName: "Attentation Status", width: 140 };
        break;
      case "chartkey":
        colDef = {
          ...colDef,
          headerName: "Chart Key",
          headerTooltip: "Chart Key",
          width: 75,
        };
        break;
      case "hccv12_result":
        colDef = {
          ...colDef,
          headerName: "HCCV12 Result",
          headerTooltip: "HCCV12 Result",
          width: 60,
        };
        break;
      case "hccv12_value":
        colDef = {
          ...colDef,
          headerName: "HCCV12 Value",
          headerTooltip: "HCCV12 Value",
          width: 65,
        };
        break;
      case "v12_chartid":
        colDef = {
          ...colDef,
          headerName: "HCCV12 Chart ID",
          headerTooltip: "HCCV12 ChartID",
          width: 90,
        };
        break;
      case "validation":
        colDef = {
          ...colDef,
          headerName: "Validation",
          headerTooltip: "Validation",
          width: 70,
          cellStyle: validColorCoding,
        };
        break;
      case "validation_":
        colDef = {
          ...colDef,
          headerName: "Validation",
          headerTooltip: "Validation",
          width: 70,
          cellStyle: validColorCoding,
        };
        break;
      case "mhcid":
        colDef = {
          ...colDef,
          headerName: "MHC ID",
          headerTooltip: "MHC ID",
          width: 70,
        };
        break;
      case "best_result":
        colDef = {
          ...colDef,
          headerName: "Best Result",
          headerTooltip: "Best Result",
          width: 55,
        };
        break;
      case "goldenticketpriorityhcc":
        colDef = {
          ...colDef,
          headerName: "Golden Ticket Priority HCC",
          headerTooltip: "Golden Ticket Priority HCC",
          width: 65,
        };
        break;
      case "hccv22":
        colDef = {
          ...colDef,
          headerName: "HCCV22",
          headerTooltip: "HCCV22",
          width: 50,
        };
        break;
      case "hccv22_validation":
        colDef = {
          ...colDef,
          headerName: "HCCV22 Validation",
          headerTooltip: "HCCV22 Validation",
          width: 70,
          cellStyle: validColorCoding,
        };
        break;
      case "hccv22_result":
        colDef = {
          ...colDef,
          headerName: "HCCV22 Result",
          headerTooltip: "HCCV22 Result",
          width: 60,
        };
        break;
      case "hccv22_value":
        colDef = {
          ...colDef,
          headerName: "HCCV22 Value",
          headerTooltip: "HCCV22 Value",
          width: 65,
        };
        break;
      case "v22_chartid":
        colDef = {
          ...colDef,
          headerName: "HCCV22 Chart ID",
          headerTooltip: "HCCV22 ChartID",
          width: 90,
        };
        break;
      case "retrproviderlastname":
        colDef = {
          ...colDef,
          headerName: "Last Name",
          headerTooltip: "Last Name",
          width: 110,
        };
        break;
      case "retrproviderfirstname":
        colDef = {
          ...colDef,
          headerName: "First Name",
          headerTooltip: "First Name",
          width: 90,
        };
        break;
      case "retrprovideraddress1":
        colDef = {
          ...colDef,
          headerName: "Address 1",
          headerTooltip: "Address 1",
          width: 110,
        };
        break;
      case "retrprovidercity":
        colDef = {
          ...colDef,
          headerName: "City",
          headerTooltip: "City",
          width: 90,
        };
        break;
      case "internal":
        colDef = { ...colDef, headerName: "Internal?", width: 50 };
        break; //need to verify
      case "attkey":
        colDef = {
          ...colDef,
          headerName: "Att ID",
          headerTooltip: "Att ID",
          width: 65,
        };
        break;
      case "isvalids":
        colDef = {
          ...colDef,
          headerName: "Is Valid",
          headerTooltip: "Is Valid",
          width: 50,
        };
        break;
      case "issuborders":
        colDef = {
          ...colDef,
          headerName: "Is Suborder",
          headerTooltip: "Is Suborder",
          width: 60,
        };
        break;
      case "validhccs":
        colDef = {
          ...colDef,
          headerName: "Valid HCCs",
          headerTooltip: "Valid HCCs",
          filter: "agNumberColumnFilter",
          width: 60,
        };
        break;
      case "goldenticketpriority":
        colDef = {
          ...colDef,
          headerName: "GT Priority",
          headerTooltip: "Golden Ticket Priority",
          width: 60,
        };
        break;
      case "isresolved":
        colDef = {
          ...colDef,
          headerName: "Is Resolved",
          headerTooltip: "Is Resolved",
          width: 60,
        };
        break;
      case "comment":
        colDef = {
          ...colDef,
          headerName: "Comment",
          headerTooltip: "Comment",
          width: 335,
        };
        break;
      case "addeddate":
        colDef = {
          ...colDef,
          headerName: "Date",
          headerTooltip: "Added Date",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "addedby":
        colDef = {
          ...colDef,
          headerName: "By",
          headerTooltip: "Added By",
          width: 70,
        };
        break;
      case "actionitem":
        colDef = {
          ...colDef,
          headerName: "Action",
          headerTooltip: "Action Item",
          width: 100,
        };
        break;
      case "actionoutcome":
        colDef = {
          ...colDef,
          headerName: "Outcome",
          headerTooltip: "Action Outcome",
          width: 140,
        };
        break;
      case "submissionorder":
        colDef = {
          ...colDef,
          headerName: "Submission Order",
          headerTooltip: "Submission Order",
          width: 60,
        };
        break;
      case "submissionkey":
        colDef = {
          ...colDef,
          headerName: "Submission Key",
          headerTooltip: "Submission Key",
          width: 80,
        };
        break;
      case "resultorder":
        colDef = {
          ...colDef,
          headerName: "Result",
          headerTooltip: "Result Order",
          width: 90,
        };
        break;
      case "attestationneeded":
        colDef = {
          ...colDef,
          headerName: "Att Needed",
          headerTooltip: "Attestation Needed",
          width: 60,
        };
        break;
      case "pages":
        colDef = {
          ...colDef,
          headerName: "Pages",
          headerTooltip: "Pages",
          width: 80,
        };
        break;
      case "icodifyreleasedate":
        colDef = {
          ...colDef,
          headerName: "Release Date",
          headerTooltip: "Release Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "importdate":
        colDef = {
          ...colDef,
          headerName: "Date",
          headerTooltip: "Import Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 120,
        };
        break;
      case "pnpcode":
        colDef = {
          ...colDef,
          headerName: "PNP Code",
          headerTooltip: "PNP Code",
          width: 100,
        };
        break;
      case "pagecount":
        colDef = {
          ...colDef,
          headerName: "Page Count",
          headerTooltip: "Page Count",
          width: 70,
        };
        break;
      case "edgeclaimsid":
        colDef = {
          ...colDef,
          headerName: "EDGE Claims ID",
          headerTooltip: "EDGE Claims ID",
          width: 100,
        };
        break;
      case "attestationreceived":
        colDef = {
          ...colDef,
          headerName: "Att Received",
          headerTooltip: "ATT Received",
          width: 70,
        };
        break;
      // case 'reasoncode':  colDef={...colDef, headerName: "Reason", width:150};  break;
      // case 'status':  colDef={...colDef, headerName: "Status", headerTooltip:"Status", width:130};  break;
      case "pnpdate":
        colDef = {
          ...colDef,
          headerName: "PNP Date",
          headerTooltip: "PNP Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "scheduledretrievaldate":
        colDef = {
          ...colDef,
          headerName: "Scheduled Ret Date",
          headerTooltip: "Scheduled Ret Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "datereleasedforcoding":
        colDef = {
          ...colDef,
          headerName: "Date Released to Coding (Internal)",
          headerTooltip: "Date Released to Coding (Internal)",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "vendreleasedforcodingdate":
        colDef = {
          ...colDef,
          headerName: "Date Released to Coding (Vendor)",
          headerTooltip: "Date Released to Coding (Vendor)",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "datesenttoclient":
        colDef = {
          ...colDef,
          headerName: "Date Sent to Client",
          headerTooltip: "Date Sent to Client",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "pcp_providerid":
        colDef = {
          ...colDef,
          headerName: "PCP ID",
          headerTooltip: "PCP ID",
          width: 130,
        };
        break;
      case "pcp_providernpi":
        colDef = {
          ...colDef,
          headerName: "PCP NPI",
          headerTooltip: "PCP NPI",
          width: 100,
        };
        break;
      case "pcp_providertin":
        colDef = {
          ...colDef,
          headerName: "PCP Provider TIN",
          headerTooltip: "PCP Provider TIN",
          width: 90,
        };
        break;
      case "pcp_providerlastname":
        colDef = {
          ...colDef,
          headerName: "PCP Last Name",
          headerTooltip: "PCP Last Name",
          width: 90,
        };
        break;
      case "pcp_providerfirstname":
        colDef = {
          ...colDef,
          headerName: "PCP First Name",
          headerTooltip: "PCP First Name",
          width: 90,
        };
        break;
      case "pcp_providergroupid":
        colDef = {
          ...colDef,
          headerName: "PCP Group ID",
          headerTooltip: "PCP Group ID",
          width: 100,
        };
        break;
      case "pcp_providergroupname":
        colDef = {
          ...colDef,
          headerName: "PCP Group Name",
          headerTooltip: "PCP Group Name",
          width: 90,
        };
        break;
      case "pcp_providerspecialty":
        colDef = {
          ...colDef,
          headerName: "PCP Specialty",
          headerTooltip: "PCP Specialty",
          width: 90,
        };
        break;
      case "render_providerid":
        colDef = {
          ...colDef,
          headerName: "Render ID",
          headerTooltip: "Render ID",
          width: 130,
        };
        break;
      case "render_providernpi":
        colDef = {
          ...colDef,
          headerName: "Render NPI",
          headerTooltip: "Render NPI",
          width: 100,
        };
        break;
      case "render_providerlastname":
        colDef = {
          ...colDef,
          headerName: "Render Last Name",
          headerTooltip: "Render Last Name",
          width: 140,
        };
        break;
      case "render_providerfirstname":
        colDef = {
          ...colDef,
          headerName: "Render First Name",
          headerTooltip: "Render First Name",
          width: 90,
        };
        break;
      case "render_providergroupid":
        colDef = {
          ...colDef,
          headerName: "Render Group ID",
          headerTooltip: "Render Group ID",
          width: 100,
        };
        break;
      case "render_providergroupname":
        colDef = {
          ...colDef,
          headerName: "Render Group Name",
          headerTooltip: "Render Group Name",
          width: 150,
        };
        break;
      case "render_providerspecialty":
        colDef = {
          ...colDef,
          headerName: "Render Specialty",
          headerTooltip: "Render Specialty",
          width: 90,
        };
        break;
      case "claimtype":
        colDef = {
          ...colDef,
          headerName: "Claim Type",
          headerTooltip: "Claim Type",
          width: 50,
        };
        break;
      case "hcc_v12":
        colDef = {
          ...colDef,
          headerName: "HCC V12",
          headerTooltip: "HCC V12",
          width: 60,
        };
        break;
      case "hcc_v12_desc":
        colDef = {
          ...colDef,
          headerName: "HCC V12 Desc",
          headerTooltip: "HCC V12 Description",
          width: 90,
        };
        break;
      case "hcc_v22":
        colDef = {
          ...colDef,
          headerName: "HCC V22",
          headerTooltip: "HCC V22",
          width: 60,
        };
        break;
      case "hcc_v22_desc":
        colDef = {
          ...colDef,
          headerName: "HCC V22 Desc",
          headerTooltip: "HCC V22 Description",
          width: 90,
        };
        break;
      case "dx_code":
        colDef = {
          ...colDef,
          headerName: "DX",
          headerTooltip: "DX Code",
          width: 60,
        };
        break;
      case "dx_desc":
        colDef = {
          ...colDef,
          headerName: "DX Desc",
          headerTooltip: "DX Description",
          width: 240,
        };
        break;
      case "cpt_code":
        colDef = {
          ...colDef,
          headerName: "CPT Code",
          headerTooltip: "CPT Code",
          width: 75,
        };
        break;
      case "cpt_desc":
        colDef = {
          ...colDef,
          headerName: "CPT Desc",
          headerTooltip: "CPT Description",
          width: 240,
        };
        break;
      case "cms_enrollee_id":
        colDef = {
          ...colDef,
          headerName: "CMS Enrollee ",
          headerTooltip: "CMS Enrollee ID",
          width: 95,
        };
        break;
      case "goldenticket":
        colDef = {
          ...colDef,
          headerName: "GT?",
          headerTooltip: "Golden Ticket?",
          width: 55,
        };
        break;
      case "attneeded":
        colDef = {
          ...colDef,
          headerName: "ATT Needed?",
          headerTooltip: "ATT Needed?",
          width: 50,
        };
        break;
      case "attreceived":
        colDef = {
          ...colDef,
          headerName: "ATT Rec'd?",
          headerTooltip: "ATT Rec'd?",
          width: 50,
        };
        break;
      case "obmr_status":
        colDef = {
          ...colDef,
          headerName: "OBMR Status",
          headerTooltip: "OBMR Status",
          width: 80,
        };
        break;
      case "obmr_received_date":
        colDef = {
          ...colDef,
          headerName: "OBMR Rec'd Date",
          headerTooltip: "OBMR Rec'd Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "submission_status":
        colDef = {
          ...colDef,
          headerName: "Submission Status",
          headerTooltip: "Submission Status",
          width: 120,
        };
        break;
      case "clientapprovaldate":
        colDef = {
          ...colDef,
          headerName: "Client Appr Date",
          headerTooltip: "Client Approval Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "manual_att_needed":
        colDef = {
          ...colDef,
          headerName: "Need Manual ATT",
          headerTooltip: "Need Manual ATT",
          width: 50,
        };
        break;
      case "attestationattached":
        colDef = {
          ...colDef,
          headerName: "ATT attached",
          headerTooltip: "Attestation Attached",
          width: 56,
        };
        break;
      case "submission_filename":
        colDef = {
          ...colDef,
          headerName: "Submission Filename",
          headerTooltip: "Submission Filename",
          width: 180,
        };
        break;
      case "cdat_submitted_date":
        colDef = {
          ...colDef,
          headerName: "CDAT Sub Date",
          headerTooltip: "CDAT Submitted Date",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "submitter":
        colDef = {
          ...colDef,
          headerName: "Submitter",
          headerTooltip: "Submitter",
          width: 70,
        };
        break;
      case "upload_ok":
        colDef = {
          ...colDef,
          headerName: "Upload OK?",
          headerTooltip: "Upload OK?",
          editable: true,
          width: 50,
        };
        break; //cellRenderer:CheckboxRenderer,
      case "upload_ok_person":
        colDef = {
          ...colDef,
          headerName: "Upload OK By",
          headerTooltip: "Upload OK By",
          width: 70,
        };
        break;
      case "attestationneededbyoptum":
        colDef = {
          ...colDef,
          headerName: "ATT Needed",
          headerTooltip: "Attestation Needed By Optum",
          width: 85,
        };
        break;
      case "attestationreceiveddate":
        colDef = {
          ...colDef,
          headerName: "ATT Recd",
          headerTooltip: "Attestation Received Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "hccv12_reasoncode":
        colDef = {
          ...colDef,
          headerName: "Reason V12",
          headerTooltip: "Reason Code V12",
          width: 120,
        };
        break;
      case "reasoncode":
        colDef = {
          ...colDef,
          headerName: "Reason V22 /Primary",
          headerTooltip: "Reason Code V22 Primary",
          width: 90,
        };
        break;
      case "commentsbyicodify":
        colDef = {
          ...colDef,
          headerName: "Comments By iCodify",
          headerTooltip: "Comments By iCodify",
          width: 120,
        };
        break;
      case "qaresult":
        colDef = {
          ...colDef,
          headerName: "QA Result",
          headerTooltip: "QA Result",
          width: 90,
        };
        break;
      case "qaproblemlistweight":
        colDef = {
          ...colDef,
          headerName: "Problem List",
          headerTooltip: "Problem List",
        };
        break;
      case "qapmhweight":
        colDef = { ...colDef, headerName: "PMH", headerTooltip: "PMH" };
        break;
      case "qapeweight":
        colDef = { ...colDef, headerName: "PE", headerTooltip: "PE" };
        break;
      case "qaimpressionweight":
        colDef = {
          ...colDef,
          headerName: "Impression",
          headerTooltip: "Impression",
        };
        break;
      case "qatreatmentplanweight":
        colDef = {
          ...colDef,
          headerName: "Treatment /Plan",
          headerTooltip: "Treatment/Plan",
        };
        break;
      case "qahpiweight":
        colDef = { ...colDef, headerName: "HPI", headerTooltip: "HPI" };
        break;
      case "qapshweight":
        colDef = { ...colDef, headerName: "PSH", headerTooltip: "PSH" };
        break;
      case "qamedsothersupportdocsweight":
        colDef = {
          ...colDef,
          headerName: "Meds /Support Docs",
          headerTooltip: "Meds/Support Docs",
        };
        break;
      case "ecssiteid":
        colDef = {
          ...colDef,
          headerName: "Site ID",
          headerTooltip: "ECS Site ID",
          width: 90,
        };
        break;
      case "qa_complete":
        colDef = {
          ...colDef,
          headerName: "QA Complete",
          headerTooltip: "QA Complete",
          width: 50,
        };
        break;
      case "outstanding_qa":
        colDef = {
          ...colDef,
          headerName: "Outstanding QA",
          headerTooltip: "Outstanding QA",
          width: 50,
        };
        break;
      case "retrieved_qa_percent":
        colDef = {
          ...colDef,
          headerName: "Retrieved QA %",
          headerTooltip: "Retrieved QA %",
          width: 70,
        };
        break;
      case "overall_qa_percent":
        colDef = {
          ...colDef,
          headerName: "Overall QA %",
          headerTooltip: "Overall QA %",
          width: 70,
        };
        break;
      case "valid_hccs_accepted":
        colDef = {
          ...colDef,
          headerName: "Valid HCCs Accepted",
          headerTooltip: "Valid HCCs Accepted",
          width: 60,
        };
        break;
      case "valid_hccs_from_qa":
        colDef = {
          ...colDef,
          headerName: "Vaild HCCs from QA",
          headerTooltip: "Vaild HCCs from QA",
          width: 60,
        };
        break;
      case "not_valid_hccs_accepted":
        colDef = {
          ...colDef,
          headerName: "Not Valid HCCs Accepted",
          headerTooltip: "Not Valid HCCs Accepted",
          width: 60,
        };
        break;
      case "not_valid_hccs_from_qa":
        colDef = {
          ...colDef,
          headerName: "Not Valid HCCs from QA",
          headerTooltip: "Not Valid HCCs from QA",
          width: 60,
        };
        break;
      case "hccs_with_oustanding_qa":
        colDef = {
          ...colDef,
          headerName: "HCCs with Outstanding",
          headerTooltip: "HCCs with Outstanding",
          width: 60,
        };
        break;
      case "total_add_hccs":
        colDef = {
          ...colDef,
          headerName: "Total Add HCCs",
          headerTooltip: "Total Add HCCs",
          width: 50,
        };
        break;
      case "add_hccs_accepted":
        colDef = {
          ...colDef,
          headerName: "Add HCCs Accepted",
          headerTooltip: "Add HCCs Accepted",
          width: 60,
        };
        break;
      case "add_hccs_denied":
        colDef = {
          ...colDef,
          headerName: "Add HCCs Denied",
          headerTooltip: "Add HCCs Denied",
          width: 50,
        };
        break;
      case "add_hccs_found_in_qa":
        colDef = {
          ...colDef,
          headerName: "Add HCCs Found in QA",
          headerTooltip: "Add HCCs Found in QA",
          width: 60,
        };
        break;
      case "add_hccs_not_qad":
        colDef = {
          ...colDef,
          headerName: "Add HCCs Not QA'd",
          headerTooltip: "Add HCCs Not QA'd",
          width: 60,
        };
        break;
      case "qa_selected":
        colDef = {
          ...colDef,
          headerName: "QA Selected",
          headerTooltip: "QA Selected",
          width: 100,
        };
        break;
      case "barcodeid":
        colDef = {
          ...colDef,
          headerName: "Barcode ID",
          headerTooltip: "Barcode ID",
          width: 200,
        };
        break;
      case "priority_a":
        colDef = {
          ...colDef,
          headerName: "Priority A",
          headerTooltip: "Priority A",
          width: 80,
        };
        break;
      case "priority_b":
        colDef = {
          ...colDef,
          headerName: "Priority B",
          headerTooltip: "Priority B",
          width: 80,
        };
        break;
      case "coding_received":
        colDef = {
          ...colDef,
          headerName: "Coding Rec'd?",
          headerTooltip: "Coding Rec'd?",
          width: 50,
        };
        break;
      case "qa_required":
        colDef = {
          ...colDef,
          headerName: "QA Req'd?",
          headerTooltip: "QA Req'd?",
          width: 50,
        };
        break;
      case "qastatus":
        colDef = {
          ...colDef,
          headerName: "QA Status",
          headerTooltip: "QA Status",
          width: 80,
        };
        break;
      case "qawhen":
        colDef = {
          ...colDef,
          headerName: "QA Date",
          headerTooltip: "QA Date",
          cellClass: "isDTime",
          width: 90,
        };
        break;
      case "qaby":
        colDef = {
          ...colDef,
          headerName: "QA By",
          headerTooltip: "QA By",
          width: 75,
        };
        break;
      case "cscoid":
        colDef = {
          ...colDef,
          headerName: "CSCO ID",
          headerTooltip: "CSCO ID",
          width: 60,
        };
        break;
      case "csco_id":
        colDef = {
          ...colDef,
          headerName: "CSCO ID",
          headerTooltip: "CSCO ID",
          width: 60,
        };
        break;
      case "highpriority":
        colDef = {
          ...colDef,
          headerName: "Priority",
          headerTooltip: "High Priority?",
          width: 50,
          valueFormatter: (params) => (params.value === 1 ? "Yes" : "No"),
        };
        break; //needs a handler yes/no
      case "cscotype":
        colDef = {
          ...colDef,
          headerName: "Type",
          headerTooltip: "CSCO Type",
          width: 80,
        };
        break;
      case "timezone":
        colDef = {
          ...colDef,
          headerName: "TimeZone",
          headerTooltip: "TimeZone",
          width: 50,
        };
        break;
      case "cscosource":
        colDef = {
          ...colDef,
          headerName: "Source",
          headerTooltip: "CSCO Source",
          width: 70,
        };
        break;
      case "cscosstatus":
        colDef = {
          ...colDef,
          headerName: "Status",
          headerTooltip: "CSCO Status",
          width: 70,
        };
        break;
      case "cscostatus":
        colDef = {
          ...colDef,
          headerName: "Status",
          headerTooltip: "CSCO Status",
          width: 80,
        };
        break;
      case "cscorejectcode":
        colDef = {
          ...colDef,
          headerName: "Reject Code",
          headerTooltip: "CSCO Reject code",
          width: 80,
        };
        break;
      case "internalchart":
        colDef = {
          ...colDef,
          headerName: "Internal Chart",
          headerTooltip: "Internal Chart",
          width: 80,
        };
        break;
      case "phone":
        colDef = {
          ...colDef,
          headerName: "Phone",
          headerTooltip: "Phone",
          width: 85,
        };
        break;
      case "department":
        colDef = {
          ...colDef,
          headerName: "Department",
          headerTooltip: "Department",
          width: 120,
        };
        break;
      case "facilityname":
        colDef = {
          ...colDef,
          headerName: "Facility Name",
          headerTooltip: "Facility Name",
          width: 120,
        };
        break;
      case "gender":
        colDef = {
          ...colDef,
          headerName: "Gender",
          headerTooltip: "Gender",
          width: 80,
        };
        break;
      case "drg":
        colDef = {
          ...colDef,
          headerName: "DRG",
          headerTooltip: "DRG",
          width: 80,
        };
        break;
      case "letterheadlink":
        colDef = {
          ...colDef,
          headerName: "Contact Link",
          headerTooltip: "Contact Link",
          width: 70,
        };
        break;
      case "packagelink":
        colDef = {
          ...colDef,
          headerName: "Package Link",
          headerTooltip: "Package Link",
          width: 70,
        };
        break;
      case "qachecklistlink":
        colDef = {
          ...colDef,
          headerName: "QA Checklist Link",
          headerTooltip: "QA Checklist Link",
          width: 70,
        };
        break;
      case "submittedby":
        colDef = {
          ...colDef,
          headerName: "Submitted By",
          headerTooltip: "Submitted By",
          width: 70,
        };
        break;
      case "secondapprover":
        colDef = {
          ...colDef,
          headerName: "Second Approver",
          headerTooltip: "Second Approver",
          width: 70,
        };
        break;
      case "submissiondate":
        colDef = {
          ...colDef,
          headerName: "Submission Date",
          headerTooltip: "Submission Date",
          width: 80,
          valueFormatter: (params) => formatDateUTC(params.value),
        };
        break;
      case "cscoowner":
        colDef = {
          ...colDef,
          headerName: "CSCO Owner",
          headerTooltip: "CSCO Owner",
          width: 70,
        };
        break;
      case "fax":
        colDef = {
          ...colDef,
          headerName: "Fax #",
          headerTooltip: "Fax #",
          width: 85,
        };
        break;
      // case 'secondapprover':  colDef={...colDef, headerName: "Second Approver",headerTooltip:'Second Approver', width:70};  break;
      case "review_year":
        colDef = {
          ...colDef,
          headerName: "Review Year",
          headerTooltip: "Review Year",
          width: 60,
        };
        break;
      case "count_of_hccs_minus_total":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Total",
          headerTooltip: "Count of HCCs Minus Total",
          width: 90,
        };
        break;
      case "sum_of_hcc_value_minus_total":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Total",
          headerTooltip: "Sum of HCC Value Minus Total",
          width: 95,
        };
        break;
      case "count_of_hccs_minus_valid":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Valid",
          headerTooltip: "Count of HCCs Minus Valid",
          width: 90,
        };
        break;
      case "sum_of_hcc_value_minus_valid":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Valid",
          headerTooltip: "Sum of HCC Value Minus Valid",
          width: 95,
        };
        break;
      case "count_of_hccs_minus_non_validated":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus NonValidated",
          headerTooltip: "Count of HCCs Minus NonValidated",
          width: 105,
        };
        break;
      case "sum_of_hcc_value_minus_non_validated":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus NonValidated",
          headerTooltip: "Sum of HCC Value Minus NonValidated",
          width: 105,
        };
        break;
      case "count_of_hccs_minus_validated_higher":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Validated Higher",
          headerTooltip: "Count of HCCs Minus Validated Higher",
          width: 105,
        };
        break;
      case "sum_of_hcc_value_minus_validated_higher":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Validated Higher",
          headerTooltip: "Sum of HCC Value Minus Validated Higher",
          width: 105,
        };
        break;
      case "count_of_hccs_minus_validated_lower":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Validated Lower",
          headerTooltip: "Count of HCCs Minus Validated Lower",
          width: 105,
        };
        break;
      case "sum_of_hcc_value_minus_validated_lower":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Validated Lower",
          headerTooltip: "Sum of HCC Value Minus Validated Lower",
          width: 105,
        };
        break;
      case "count_of_hccs_minus_validated_maybe":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Validated Maybe",
          headerTooltip: "Count of HCCs Minus Validated Maybe",
          width: 105,
        };
        break;
      case "sum_of_hcc_value_minus_validated_maybe":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Validated Maybe",
          headerTooltip: "Sum of HCC Value Minus Validated Maybe",
          width: 105,
        };
        break;
      case "count_of_hccs_minus_awaiting_validation":
        colDef = {
          ...colDef,
          headerName: "Count of HCCs Minus Awaiting Validation",
          headerTooltip: "Count of HCCs Minus Awaiting Validation",
          width: 105,
        };
        break;
      case "sum_of_hcc_value_minus_awaiting_validation":
        colDef = {
          ...colDef,
          headerName: "Sum of HCC Value Minus Awaiting Validation",
          headerTooltip: "Sum of HCC Value Minus Awaiting Validation",
          width: 105,
        };
        break;
      case "completesubpath":
        colDef = {
          ...colDef,
          headerName: "Path Copy Edit",
          headerTooltip: "Complete SubPath",
          width: 70,
          editable: false,
        };
        break; //, cellRenderer:(params)=>`<button>Go</button>`
      case "addhcc":
        colDef = {
          ...colDef,
          headerName: "Add HCC",
          headerTooltip: "Add HCC",
          width: 65,
        };
        break;
      case "addhccvalue":
        colDef = {
          ...colDef,
          headerName: "Add HCC Value",
          headerTooltip: "Add HCC Value",
          width: 75,
        };
        break;
      case "datevaluereceived":
        colDef = {
          ...colDef,
          headerName: "Date Value Received",
          headerTooltip: "Date Value Received",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 95,
        };
        break;
      case "memberhicnumber":
        colDef = {
          ...colDef,
          headerName: "Member HICN",
          headerTooltip: "Member HICN",
          width: 95,
        };
        break;
      case "thrudos":
        colDef = {
          ...colDef,
          headerName: "Thru DOS",
          headerTooltip: "Thru DOS",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "dx_key":
        colDef = {
          ...colDef,
          headerName: "DX Key",
          headerTooltip: "DX Key",
          width: 80,
        };
        break;
      case "enc_key":
        colDef = {
          ...colDef,
          headerName: "Enc Key",
          headerTooltip: "Enc Key",
          width: 80,
        };
        break;
      case "addeddatesim":
        colDef = {
          ...colDef,
          headerName: "Added Date SIM",
          headerTooltip: "Added Date SIM",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 95,
        };
        break;
      case "countcscoid":
        colDef = {
          ...colDef,
          headerName: "Count CSCO ID",
          headerTooltip: "Count CSCO ID",
          width: 95,
        };
        break;
      case "sumcmtsinprogress":
        colDef = {
          ...colDef,
          headerName: "Sum Cmts in Progress",
          headerTooltip: "Sum Cmts in Progress",
          width: 95,
        };
        break;
      case "countidsinprogress":
        colDef = {
          ...colDef,
          headerName: "Count IDs in Progress",
          headerTooltip: "Count IDs in Progress",
          width: 95,
        };
        break;
      case "sumhold":
        colDef = {
          ...colDef,
          headerName: "Sum Hold",
          headerTooltip: "Sum Hold",
          width: 95,
        };
        break;
      case "countidshold":
        colDef = {
          ...colDef,
          headerName: "Count IDs Hold",
          headerTooltip: "Count IDs Hold",
          width: 95,
        };
        break;
      case "sumreturned":
        colDef = {
          ...colDef,
          headerName: "Sum Returned",
          headerTooltip: "Sum Returned",
          width: 95,
        };
        break;
      case "countidsreturned":
        colDef = {
          ...colDef,
          headerName: "Count IDs Returned",
          headerTooltip: "Count IDs Returned",
          width: 95,
        };
        break;
      case "sumsentprov":
        colDef = {
          ...colDef,
          headerName: "Sum Sent Prov",
          headerTooltip: "Sum Sent Prov",
          width: 95,
        };
        break;
      case "countidssentprov":
        colDef = {
          ...colDef,
          headerName: "Count IDs Sent Prov",
          headerTooltip: "Count IDs Sent Prov",
          width: 95,
        };
        break;
      case "sumclosed":
        colDef = {
          ...colDef,
          headerName: "Sum Closed",
          headerTooltip: "Sum Closed",
          width: 95,
        };
        break;
      case "countidsclosed":
        colDef = {
          ...colDef,
          headerName: "Count IDs Closed",
          headerTooltip: "Count IDs Closed",
          width: 95,
        };
        break;
      case "countinprogress":
        colDef = {
          ...colDef,
          headerName: "Count in Progress",
          headerTooltip: "Count in Progress",
          width: 95,
        };
        break;
      case "counthold":
        colDef = {
          ...colDef,
          headerName: "Count Hold",
          headerTooltip: "Count Hold",
          width: 95,
        };
        break;
      case "countreturned":
        colDef = {
          ...colDef,
          headerName: "Count Returned",
          headerTooltip: "Count Returned",
          width: 95,
        };
        break;
      case "countsentprov":
        colDef = {
          ...colDef,
          headerName: "Count Sent Prov",
          headerTooltip: "Count Sent Prov",
          width: 95,
        };
        break;
      case "countclosed":
        colDef = {
          ...colDef,
          headerName: "Count Closed",
          headerTooltip: "Count Closed",
          width: 95,
        };
        break;
      case "pqkey":
        colDef = {
          ...colDef,
          headerName: "PQ Key",
          headerTooltip: "PQ Key",
          width: 95,
        };
        break;
      case "activitydatetime":
        colDef = {
          ...colDef,
          headerName: "Activity Date/ Time",
          headerTooltip: "Activity Date/Time",
          cellClass: "isDTime",
          width: 145,
        };
        break;
      case "type":
        colDef = {
          ...colDef,
          headerName: "Type",
          headerTooltip: "Type",
          width: 70,
        };
        break;
      case "source":
        colDef = {
          ...colDef,
          headerName: "Source",
          headerTooltip: "Source",
          width: 85,
        };
        break;
      case "cscostage":
        colDef = {
          ...colDef,
          headerName: "CSCO Stage",
          headerTooltip: "CSCO Stage",
          width: 60,
        };
        break;
      case "cscostagename":
        colDef = {
          ...colDef,
          headerName: "CSCO Stage Name",
          headerTooltip: "CSCO Stage Name",
          width: 85,
        };
        break;
      case "added_date":
        colDef = {
          ...colDef,
          headerName: "Added Date",
          headerTooltip: "Added Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "date_closed_or_completed":
        colDef = {
          ...colDef,
          headerName: "Date Closed or Completed",
          headerTooltip: "Date Closed or Completed",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "notstartedcount":
        colDef = {
          ...colDef,
          headerName: "Not Started Count",
          headerTooltip: "Not Started Count",
          width: 60,
        };
        break;
      case "waitingcount":
        colDef = {
          ...colDef,
          headerName: "Waiting Count",
          headerTooltip: "Waiting Count",
          width: 60,
        };
        break;
      case "second_approval_requested_date":
        colDef = {
          ...colDef,
          headerName: "Second Approval Requested Date",
          headerTooltip: "Second Approval Requested Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "senttocscocount":
        colDef = {
          ...colDef,
          headerName: "Sent to CSCO Count",
          headerTooltip: "Sent to CSCO Count",
          width: 60,
        };
        break;
      case "date_sent_to_csco":
        colDef = {
          ...colDef,
          headerName: "Date Sent to CSCO",
          headerTooltip: "Date Sent to CSCO",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "inprogresscount":
        colDef = {
          ...colDef,
          headerName: "In Progress Count",
          headerTooltip: "In Progress Count",
          width: 60,
        };
        break;
      case "date_csco_set_to_in_progress":
        colDef = {
          ...colDef,
          headerName: "Date CSCO Set to In Progress",
          headerTooltip: "Date CSCO Set to In Progress",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 95,
        };
        break;
      case "cscoreturnedcount":
        colDef = {
          ...colDef,
          headerName: "CSCO Returned Count",
          headerTooltip: "CSCO Returned Count",
          width: 60,
        };
        break;
      case "date_csco_returned_to_user":
        colDef = {
          ...colDef,
          headerName: "Date CSCO Returned to User",
          headerTooltip: "Date CSCO Returned to User",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "cscoholdcount":
        colDef = {
          ...colDef,
          headerName: "CSCO Hold Count",
          headerTooltip: "CSCO Hold Count",
          width: 60,
        };
        break;
      case "date_csco_put_on_hold":
        colDef = {
          ...colDef,
          headerName: "Date CSCO Put on Hold",
          headerTooltip: "Date CSCO Put on Hold",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "completedcount":
        colDef = {
          ...colDef,
          headerName: "Completed Count",
          headerTooltip: "Completed Count",
          width: 60,
        };
        break;
      case "date_completed":
        colDef = {
          ...colDef,
          headerName: "Date Completed",
          headerTooltip: "Date Completed",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "closedcount":
        colDef = {
          ...colDef,
          headerName: "Closed Count",
          headerTooltip: "Closed Count",
          width: 60,
        };
        break;
      case "date_closed":
        colDef = {
          ...colDef,
          headerName: "Date Closed",
          headerTooltip: "Date Closed",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "package_link":
        colDef = {
          ...colDef,
          headerName: "Package Link",
          headerTooltip: "Package Link",
          width: 60,
        };
        break;
      case "chartfilelink":
        colDef = {
          ...colDef,
          headerName: "Chart Link",
          headerTooltip: "Chart File Link",
          width: 60,
        };
        break;
      case "letterhead_link":
        colDef = {
          ...colDef,
          headerName: "Letterhead Link",
          headerTooltip: "Letterhead Link",
          width: 60,
        };
        break;
      case "qa_checklist_link":
        colDef = {
          ...colDef,
          headerName: "QA Checklist Link",
          headerTooltip: "QA Checklist Link",
          width: 60,
        };
        break;
      case "provider_last_name":
        colDef = {
          ...colDef,
          headerName: "Provider Last Name",
          headerTooltip: "Provider Last Name",
          width: 150,
        };
        break;
      case "provider_first_name":
        colDef = {
          ...colDef,
          headerName: "Provider First Name",
          headerTooltip: "Provider First Name",
          width: 100,
        };
        break;
      case "provider_address":
        colDef = {
          ...colDef,
          headerName: "Provider Address",
          headerTooltip: "Provider Address",
          width: 110,
        };
        break;
      case "provider_address_2":
        colDef = {
          ...colDef,
          headerName: "Provider Address 2",
          headerTooltip: "Provider Address 2",
          width: 90,
        };
        break;
      case "provider_city":
        colDef = {
          ...colDef,
          headerName: "Provider City",
          headerTooltip: "Provider City",
          width: 90,
        };
        break;
      case "provider_state":
        colDef = {
          ...colDef,
          headerName: "Provider State",
          headerTooltip: "Provider State",
          width: 60,
        };
        break;
      case "provider_zip":
        colDef = {
          ...colDef,
          headerName: "Provider Zip",
          headerTooltip: "Provider Zip",
          width: 70,
        };
        break;
      case "provider_phone":
        colDef = {
          ...colDef,
          headerName: "Provider Phone",
          headerTooltip: "Provider Phone",
          width: 90,
        };
        break;
      case "provider_fax":
        colDef = {
          ...colDef,
          headerName: "Provider Fax",
          headerTooltip: "Provider Fax",
          width: 90,
        };
        break;
      case "provider_email":
        colDef = {
          ...colDef,
          headerName: "Provider Email",
          headerTooltip: "Provider Email",
          width: 90,
        };
        break;
      case "zip":
        colDef = {
          ...colDef,
          headerName: "Zip",
          headerTooltip: "Zip",
          width: 60,
        };
        break;
      case "altphone":
        colDef = {
          ...colDef,
          headerName: "Alt Phone",
          headerTooltip: "Alt Phone",
          width: 90,
        };
        break;
      case "contact_selected":
        colDef = {
          ...colDef,
          headerName: "Contact Selected",
          headerTooltip: "Contact Selected",
          width: 60,
        };
        break;
      case "priority":
        colDef = {
          ...colDef,
          headerName: "Priority",
          headerTooltip: "Priority",
          width: 60,
        };
        break;
      case "approver_1":
        colDef = {
          ...colDef,
          headerName: "Approver 1",
          headerTooltip: "Approver 1",
          width: 80,
        };
        break;
      case "approver_2":
        colDef = {
          ...colDef,
          headerName: "Approver 2",
          headerTooltip: "Approver 2",
          width: 80,
        };
        break;
      case "rightfaxid":
        colDef = {
          ...colDef,
          headerName: "Rightfax ID",
          headerTooltip: "Rightfax ID",
          width: 80,
        };
        break;
      case "mail_to_attention_of":
        colDef = {
          ...colDef,
          headerName: "Mail to Attention of",
          headerTooltip: "Mail to Attention of",
          width: 90,
        };
        break;
      case "file_password":
        colDef = {
          ...colDef,
          headerName: "File Password",
          headerTooltip: "File Password",
          width: 80,
        };
        break;
      case "ups_send_tracking":
        colDef = {
          ...colDef,
          headerName: "UPS Send Tracking",
          headerTooltip: "UPS Send Tracking",
          width: 100,
        };
        break;
      case "ups_return_tracking":
        colDef = {
          ...colDef,
          headerName: "UPS Return Tracking",
          headerTooltip: "UPS Return Tracking",
          width: 100,
        };
        break;
      case "ups_send_link":
        colDef = {
          ...colDef,
          headerName: "UPS Send Link",
          headerTooltip: "UPS Send Link",
          width: 60,
        };
        break;
      case "ups_return_link":
        colDef = {
          ...colDef,
          headerName: "UPS Return Link",
          headerTooltip: "UPS Return Link",
          width: 60,
        };
        break;
      case "icd9_description":
        colDef = {
          ...colDef,
          headerName: "ICD9 Description",
          headerTooltip: "ICD9 Description",
          width: 90,
        };
        break;
      case "missing_medical_record":
        colDef = {
          ...colDef,
          headerName: "Missing Medical Record",
          headerTooltip: "Missing Medical Record",
          width: 90,
        };
        break;
      case "diagnosis_ranges":
        colDef = {
          ...colDef,
          headerName: "Diagnosis Ranges",
          headerTooltip: "Diagnosis Ranges",
          width: 90,
        };
        break;
      case "coding_discrepancy":
        colDef = {
          ...colDef,
          headerName: "Coding Discrepancy",
          headerTooltip: "Coding Discrepancy",
          width: 80,
        };
        break;
      case "missing_or_illegible_signature_or_missing_credential":
        colDef = {
          ...colDef,
          headerName: "Missing or Illegible Signature or Missing Credential",
          headerTooltip: "Missing or Illegible Signature or Missing Credential",
          width: 80,
        };
        break;
      case "invalid_medical_record":
        colDef = {
          ...colDef,
          headerName: "Invalid Medical Record",
          headerTooltip: "Invalid Medical Record",
          width: 90,
        };
        break;
      case "comments":
        colDef = {
          ...colDef,
          headerName: "Comments",
          headerTooltip: "Comments",
          width: 120,
        };
        break;
      case "additional_comments":
        colDef = {
          ...colDef,
          headerName: "Additional Comments",
          headerTooltip: "Additional Comments",
          width: 150,
        };
        break;
      case "member_hcc_count":
        colDef = {
          ...colDef,
          headerName: "Member HCC Count",
          headerTooltip: "Member HCC Count",
          width: 60,
        };
        break;
      case "cancelled_records":
        colDef = {
          ...colDef,
          headerName: "Cancelled Records",
          headerTooltip: "Cancelled Records",
          width: 60,
        };
        break;
      case "pnp_over_cna":
        colDef = {
          ...colDef,
          headerName: "PNP Over CNA",
          headerTooltip: "PNP Over CNA",
          width: 60,
        };
        break;
      case "outstanding_medical_records":
        colDef = {
          ...colDef,
          headerName: "Outstanding Medical Records",
          headerTooltip: "Outstanding Medical Records",
          width: 60,
        };
        break;
      case "retrieval_rate":
        colDef = {
          ...colDef,
          headerName: "Retrieval Rate",
          headerTooltip: "Retrieval Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
        };
        break;
      case "member_hccs_uploaded_to_cms":
        colDef = {
          ...colDef,
          headerName: "Member HCCs Uploaded to CMS",
          headerTooltip: "Member HCCs Uploaded to CMS",
          width: 60,
        };
        break;
      case "pnp_cna":
        colDef = {
          ...colDef,
          headerName: "PNP/CNA",
          headerTooltip: "PNP/CNA",
          width: 60,
        };
        break;
      case "groupkey":
        colDef = {
          ...colDef,
          headerName: "Group Key",
          headerTooltip: "Group Key",
          width: 85,
        };
        break;
      case "parentgroupkey":
        colDef = {
          ...colDef,
          headerName: "Parent Group Key",
          headerTooltip: "Parent Group Key",
          width: 85,
        };
        break;
      case "parentgroupid":
        colDef = {
          ...colDef,
          headerName: "Parent Group ID",
          headerTooltip: "Parent Group ID",
          width: 85,
        };
        break;
      case "parentgroupname":
        colDef = {
          ...colDef,
          headerName: "Parent Group Name",
          headerTooltip: "Parent Group Name",
          width: 160,
        };
        break;
      case "daterange":
        colDef = {
          ...colDef,
          headerName: "Date Range",
          headerTooltip: "Date Range",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "total_charts_completed":
        colDef = {
          ...colDef,
          headerName: "Total Charts Completed",
          headerTooltip: "Total Charts Completed",
          width: 100,
        };
        break;
      case "total_pages":
        colDef = {
          ...colDef,
          headerName: "Total Pages",
          headerTooltip: "Total Pages",
          width: 85,
        };
        break;
      case "average_pages_per_chart":
        colDef = {
          ...colDef,
          headerName: "Avg Pages per Chart",
          headerTooltip: "Avg Pages per Chart",
          width: 85,
        };
        break;
      case "total_hccs":
        colDef = {
          ...colDef,
          headerName: "Total HCCs",
          headerTooltip: "Total HCCs",
          width: 75,
        };
        break;
      case "total_unique_chart_hccs_with_a_from_qa":
        colDef = {
          ...colDef,
          headerName: "Total Unique Chart HCCs with a From QA",
          headerTooltip: "Total Unique Chart HCCs with a From QA",
          width: 90,
        };
        break;
      case "total_qa_records_found":
        colDef = {
          ...colDef,
          headerName: "Total QA Records Found",
          headerTooltip: "Total QA Records Found",
          width: 95,
        };
        break;
      case "denied":
        colDef = {
          ...colDef,
          headerName: "Denied",
          headerTooltip: "Denied",
          width: 85,
        };
        break;
      case "valid_accepted":
        colDef = {
          ...colDef,
          headerName: "Valid Accepted",
          headerTooltip: "Valid Accepted",
          width: 100,
        };
        break;
      case "valid_from_qa_denied_the_not_valid":
        colDef = {
          ...colDef,
          headerName: "Valid from QA Denied the Not Valid",
          headerTooltip: "Valid from QA Denied the Not Valid",
          width: 90,
        };
        break;
      case "pct_result_changed_to_valid_denied_the_not_valid":
        colDef = {
          ...colDef,
          headerName: "Pct Result Changed to Valid Denied the Not Valid",
          headerTooltip: "Pct Result Changed to Valid Denied the Not Valid",
          width: 90,
        };
        break;
      case "not_valid_accepted":
        colDef = {
          ...colDef,
          headerName: "Not Valid Accepted",
          headerTooltip: "Not Valid Accepted",
          width: 90,
        };
        break;
      case "not_valid_from_overturned_valids":
        colDef = {
          ...colDef,
          headerName: "Not Valid from Overturned Valids",
          headerTooltip: "Not Valid from Overturned Valids",
          width: 110,
        };
        break;
      case "pct_result_changed_to_not_valid_denied_the_not_valid":
        colDef = {
          ...colDef,
          headerName: "Pct Result Changed to Not Valid Denied the Not Valid",
          headerTooltip: "Pct Result Changed to Not Valid Denied the Not Valid",
          width: 80,
        };
        break;
      case "qa_not_required":
        colDef = {
          ...colDef,
          headerName: "QA Not Required",
          headerTooltip: "QA Not Required",
          width: 95,
        };
        break;
      case "total_adds_accepted":
        colDef = {
          ...colDef,
          headerName: "Total Adds Accepted",
          headerTooltip: "Total Adds Accepted",
          width: 100,
        };
        break;
      case "total_adds_denied":
        colDef = {
          ...colDef,
          headerName: "Total Adds Denied",
          headerTooltip: "Total Adds Denied",
          width: 95,
        };
        break;
      case "coder":
        colDef = {
          ...colDef,
          headerName: "Coder",
          headerTooltip: "Coder",
          width: 80,
        };
        break;
      case "total_member_hccs":
        colDef = {
          ...colDef,
          headerName: "Total Member HCCs",
          headerTooltip: "Total Member HCCs",
          width: 95,
        };
        break;
      case "valid_member_hccs":
        colDef = {
          ...colDef,
          headerName: "Valid Member HCCs",
          headerTooltip: "Valid Member HCCs",
          width: 95,
        };
        break;
      case "not_valid_member_hccs":
        colDef = {
          ...colDef,
          headerName: "Not Valid Member HCCs",
          headerTooltip: "Not Valid Member HCCs",
          width: 95,
        };
        break;
      case "total_charts":
        colDef = {
          ...colDef,
          headerName: "Total Charts",
          headerTooltip: "Total Charts",
          width: 95,
        };
        break;
      case "total_chart_pages":
        colDef = {
          ...colDef,
          headerName: "Total Chart Pages",
          headerTooltip: "Total Chart Pages",
          width: 95,
        };
        break;
      case "average_pages_per_member_hcc":
        colDef = {
          ...colDef,
          headerName: "Avg Pages per Member HCC",
          headerTooltip: "Avg Pages per Member HCC",
          width: 105,
        };
        break;
      case "percent_result_changed_to_valid_denied_the_not_valid":
        colDef = {
          ...colDef,
          headerName: "Pct Result Changed to Valid Denied the Not Valid",
          headerTooltip: "Pct Result Changed to Valid Denied the Not Valid",
          width: 120,
        };
        break;
      case "percent_result_changed_to_not_valid_denied_the_not_valid":
        colDef = {
          ...colDef,
          headerName: "Pct Result Changed to Not Valid Denied the Not Valid",
          headerTooltip: "Pct Result Changed to Not Valid Denied the Not Valid",
          width: 135,
        };
        break;
      case "memberhcc":
        colDef = {
          ...colDef,
          headerName: "Member HCC",
          headerTooltip: "Member HCC",
          width: 95,
        };
        break;
      case "bestvalidation":
        colDef = {
          ...colDef,
          headerName: "Best Validation",
          headerTooltip: "Best Validation",
          width: 60,
        };
        break;
      case "nbrcharts":
        colDef = {
          ...colDef,
          headerName: "Nbr Charts",
          headerTooltip: "Nbr Charts",
          width: 80,
        };
        break;
      case "nbrdatesofservice":
        colDef = {
          ...colDef,
          headerName: "Nbr Dates of Service",
          headerTooltip: "Nbr Dates of Service",
          width: 90,
        };
        break;
      case "chartpagecount":
        colDef = {
          ...colDef,
          headerName: "Chart Page Count",
          headerTooltip: "Chart Page Count",
          width: 80,
        };
        break;
      case "calculatedpagecount":
        colDef = {
          ...colDef,
          headerName: "Calculated Page Count",
          headerTooltip: "Calculated Page Count",
          width: 95,
        };
        break;
      case "hicnhccqacomplete":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Complete",
          headerTooltip: "HICN HCC QA Complete",
          width: 95,
        };
        break;
      case "hicnhccqadoneonanother":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Done on Another",
          headerTooltip: "HICN HCC QA Done on Another",
          width: 95,
        };
        break;
      case "hicnhccqaexists":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Exists",
          headerTooltip: "HICN HCC QA Exists",
          width: 80,
        };
        break;
      case "hicnhccqadeny":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Deny",
          headerTooltip: "HICN HCC QA Deny",
          width: 75,
        };
        break;
      case "hicnhccqaaccept":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Accept",
          headerTooltip: "HICN HCC QA Accept",
          width: 85,
        };
        break;
      case "hicnhccqaacceptvalid":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Accept Valid",
          headerTooltip: "HICN HCC QA Accept Valid",
          width: 90,
        };
        break;
      case "hicnhccqaacceptnotvalid":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Accept Not Valid",
          headerTooltip: "HICN HCC QA Accept Not Valid",
          width: 95,
        };
        break;
      case "hicnhccqafromqa":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA From QA",
          headerTooltip: "HICN HCC QA From QA",
          width: 90,
        };
        break;
      case "hicnhccqaoverturn":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Overturn",
          headerTooltip: "HICN HCC QA Overturn",
          width: 85,
        };
        break;
      case "hicnhccqaother":
        colDef = {
          ...colDef,
          headerName: "HICN HCC QA Other",
          headerTooltip: "HICN HCC QA Other",
          width: 80,
        };
        break;
      case "lastinprogresswhen":
        colDef = {
          ...colDef,
          headerName: "Last in Progress When",
          headerTooltip: "Last in Progress When",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 85,
        };
        break;
      case "lastinprogressby":
        colDef = {
          ...colDef,
          headerName: "Last in Progress By",
          headerTooltip: "Last in Progress By",
          width: 85,
        };
        break;
      case "best_validation_1":
        colDef = {
          ...colDef,
          headerName: "Best Validation 1",
          headerTooltip: "Best Validation 1",
          width: 60,
        };
        break;
      case "best_validation_2":
        colDef = {
          ...colDef,
          headerName: "Best Validation 2",
          headerTooltip: "Best Validation 2",
          width: 60,
        };
        break;
      case "best_validation_3":
        colDef = {
          ...colDef,
          headerName: "Best Validation 3",
          headerTooltip: "Best Validation 3",
          width: 60,
        };
        break;
      case "best_validation_4":
        colDef = {
          ...colDef,
          headerName: "Best Validation 4",
          headerTooltip: "Best Validation 4",
          width: 60,
        };
        break;
      case "best_validation_5":
        colDef = {
          ...colDef,
          headerName: "Best Validation 5",
          headerTooltip: "Best Validation 5",
          width: 60,
        };
        break;
      case "net_medical_records_requested":
        colDef = {
          ...colDef,
          headerName: "Net Medical Records Requested",
          headerTooltip: "Net Medical Records Requested",
          width: 75,
        };
        break;
      case "total_medical_records_received":
        colDef = {
          ...colDef,
          headerName: "Total Medical Records Received",
          headerTooltip: "Total Medical Records Received",
          width: 75,
        };
        break;
      case "pct_tot_med_recs_recd_waiting_val_results":
        colDef = {
          ...colDef,
          headerName: "Pct Total Medical Recs Recd waiting Validation Results",
          headerTooltip:
            "Pct Total Medical Recs Recd waiting Validation Results",
          width: 120,
        };
        break;
      case "open_medical_records_hcc_triggered":
        colDef = {
          ...colDef,
          headerName: "Open Medical Records HCC Triggered",
          headerTooltip: "Open Medical Records HCC Triggered",
          width: 75,
        };
        break;
      case "parent_group_state":
        colDef = {
          ...colDef,
          headerName: "Parent Group State",
          headerTooltip: "Parent Group State",
          width: 50,
        };
        break;
      case "provider_name":
        colDef = {
          ...colDef,
          headerName: "Provider Name",
          headerTooltip: "Provider Name",
          width: 110,
        };
        break;
      case "result":
        colDef = {
          ...colDef,
          headerName: "Result",
          headerTooltip: "Result",
          width: 60,
        };
        break;
      case "description":
        colDef = {
          ...colDef,
          headerName: "Description",
          headerTooltip: "Description",
          width: 100,
        };
        break;
      case "coding_submission_order":
        colDef = {
          ...colDef,
          headerName: "Coding Submission Order",
          headerTooltip: "Coding Submission Order",
          width: 70,
        };
        break;
      case "higherlower":
        colDef = {
          ...colDef,
          headerName: "Higher Lower",
          headerTooltip: "Higher Lower",
          width: 60,
        };
        break;
      case "higherlower_value":
        colDef = {
          ...colDef,
          headerName: "Higher Lower Value",
          headerTooltip: "Higher Lower Value",
          width: 60,
        };
        break;
      case "additionalhcc1":
        colDef = {
          ...colDef,
          headerName: "Additional HCC1",
          headerTooltip: "Additional HCC1",
          width: 60,
        };
        break;
      case "additional_hcc1_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC1 Value",
          headerTooltip: "Additional HCC1 Value",
          width: 60,
        };
        break;
      case "additionalhcc2":
        colDef = {
          ...colDef,
          headerName: "Additional HCC2",
          headerTooltip: "Additional HCC2",
          width: 60,
        };
        break;
      case "additional_hcc2_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC2 Value",
          headerTooltip: "Additional HCC2 Value",
          width: 60,
        };
        break;
      case "additionalhcc3":
        colDef = {
          ...colDef,
          headerName: "Additional HCC3",
          headerTooltip: "Additional HCC3",
          width: 60,
        };
        break;
      case "additional_hcc3_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC3 Value",
          headerTooltip: "Additional HCC3 Value",
          width: 60,
        };
        break;
      case "additionalhcc4":
        colDef = {
          ...colDef,
          headerName: "Additional HCC4",
          headerTooltip: "Additional HCC4",
          width: 60,
        };
        break;
      case "additional_hcc4_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC4 Value",
          headerTooltip: "Additional HCC4 Value",
          width: 60,
        };
        break;
      case "additionalhcc5":
        colDef = {
          ...colDef,
          headerName: "Additional HCC5",
          headerTooltip: "Additional HCC5",
          width: 60,
        };
        break;
      case "additional_hcc5_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC5 Value",
          headerTooltip: "Additional HCC5 Value",
          width: 60,
        };
        break;
      case "additionalhcc6":
        colDef = {
          ...colDef,
          headerName: "Additional HCC6",
          headerTooltip: "Additional HCC6",
          width: 60,
        };
        break;
      case "additional_hcc6_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC6 Value",
          headerTooltip: "Additional HCC6 Value",
          width: 60,
        };
        break;
      case "additionalhcc7":
        colDef = {
          ...colDef,
          headerName: "Additional HCC7",
          headerTooltip: "Additional HCC7",
          width: 60,
        };
        break;
      case "additional_hcc7_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC7 Value",
          headerTooltip: "Additional HCC7 Value",
          width: 60,
        };
        break;
      case "additionalhcc8":
        colDef = {
          ...colDef,
          headerName: "Additional HCC8",
          headerTooltip: "Additional HCC8",
          width: 60,
        };
        break;
      case "additional_hcc8_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC8 Value",
          headerTooltip: "Additional HCC8 Value",
          width: 60,
        };
        break;
      case "additionalhcc9":
        colDef = {
          ...colDef,
          headerName: "Additional HCC9",
          headerTooltip: "Additional HCC9",
          width: 60,
        };
        break;
      case "additional_hcc9_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC9 Value",
          headerTooltip: "Additional HCC9 Value",
          width: 60,
        };
        break;
      case "additionalhcc10":
        colDef = {
          ...colDef,
          headerName: "Additional HCC10",
          headerTooltip: "Additional HCC10",
          width: 60,
        };
        break;
      case "additional_hcc10_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC10 Value",
          headerTooltip: "Additional HCC10 Value",
          width: 60,
        };
        break;
      case "additionalhcc11":
        colDef = {
          ...colDef,
          headerName: "Additional HCC11",
          headerTooltip: "Additional HCC11",
          width: 60,
        };
        break;
      case "additional_hcc11_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC11 Value",
          headerTooltip: "Additional HCC11 Value",
          width: 60,
        };
        break;
      case "additionalhcc12":
        colDef = {
          ...colDef,
          headerName: "Additional HCC12",
          headerTooltip: "Additional HCC12",
          width: 60,
        };
        break;
      case "additional_hcc12_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC12 Value",
          headerTooltip: "Additional HCC12 Value",
          width: 60,
        };
        break;
      case "additionalhcc13":
        colDef = {
          ...colDef,
          headerName: "Additional HCC13",
          headerTooltip: "Additional HCC13",
          width: 60,
        };
        break;
      case "additional_hcc13_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC13 Value",
          headerTooltip: "Additional HCC13 Value",
          width: 60,
        };
        break;
      case "additionalhcc14":
        colDef = {
          ...colDef,
          headerName: "Additional HCC14",
          headerTooltip: "Additional HCC14",
          width: 60,
        };
        break;
      case "additional_hcc14_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC14 Value",
          headerTooltip: "Additional HCC14 Value",
          width: 60,
        };
        break;
      case "additionalhcc15":
        colDef = {
          ...colDef,
          headerName: "Additional HCC15",
          headerTooltip: "Additional HCC15",
          width: 60,
        };
        break;
      case "additional_hcc15_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC15 Value",
          headerTooltip: "Additional HCC15 Value",
          width: 60,
        };
        break;
      case "additionalhcc16":
        colDef = {
          ...colDef,
          headerName: "Additional HCC16",
          headerTooltip: "Additional HCC16",
          width: 60,
        };
        break;
      case "additional_hcc16_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC16 Value",
          headerTooltip: "Additional HCC16 Value",
          width: 60,
        };
        break;
      case "additionalhcc17":
        colDef = {
          ...colDef,
          headerName: "Additional HCC17",
          headerTooltip: "Additional HCC17",
          width: 60,
        };
        break;
      case "additional_hcc17_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC17 Value",
          headerTooltip: "Additional HCC17 Value",
          width: 60,
        };
        break;
      case "additionalhcc18":
        colDef = {
          ...colDef,
          headerName: "Additional HCC18",
          headerTooltip: "Additional HCC18",
          width: 60,
        };
        break;
      case "additional_hcc18_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC18 Value",
          headerTooltip: "Additional HCC18 Value",
          width: 60,
        };
        break;
      case "additionalhcc19":
        colDef = {
          ...colDef,
          headerName: "Additional HCC19",
          headerTooltip: "Additional HCC19",
          width: 60,
        };
        break;
      case "additional_hcc19_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC19 Value",
          headerTooltip: "Additional HCC19 Value",
          width: 60,
        };
        break;
      case "additionalhcc20":
        colDef = {
          ...colDef,
          headerName: "Additional HCC20",
          headerTooltip: "Additional HCC20",
          width: 60,
        };
        break;
      case "additional_hcc20_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC20 Value",
          headerTooltip: "Additional HCC20 Value",
          width: 60,
        };
        break;
      case "additionalhcc21":
        colDef = {
          ...colDef,
          headerName: "Additional HCC21",
          headerTooltip: "Additional HCC21",
          width: 60,
        };
        break;
      case "additional_hcc21_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC21 Value",
          headerTooltip: "Additional HCC21 Value",
          width: 60,
        };
        break;
      case "additionalhcc22":
        colDef = {
          ...colDef,
          headerName: "Additional HCC22",
          headerTooltip: "Additional HCC22",
          width: 60,
        };
        break;
      case "additional_hcc22_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC22 Value",
          headerTooltip: "Additional HCC22 Value",
          width: 60,
        };
        break;
      case "additionalhcc23":
        colDef = {
          ...colDef,
          headerName: "Additional HCC23",
          headerTooltip: "Additional HCC23",
          width: 60,
        };
        break;
      case "additional_hcc23_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC23 Value",
          headerTooltip: "Additional HCC23 Value",
          width: 60,
        };
        break;
      case "additionalhcc24":
        colDef = {
          ...colDef,
          headerName: "Additional HCC24",
          headerTooltip: "Additional HCC24",
          width: 60,
        };
        break;
      case "additional_hcc24_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC24 Value",
          headerTooltip: "Additional HCC24 Value",
          width: 60,
        };
        break;
      case "additionalhcc25":
        colDef = {
          ...colDef,
          headerName: "Additional HCC25",
          headerTooltip: "Additional HCC25",
          width: 60,
        };
        break;
      case "additional_hcc25_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC25 Value",
          headerTooltip: "Additional HCC25 Value",
          width: 60,
        };
        break;
      case "additionalhcc26":
        colDef = {
          ...colDef,
          headerName: "Additional HCC26",
          headerTooltip: "Additional HCC26",
          width: 60,
        };
        break;
      case "additional_hcc26_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC26 Value",
          headerTooltip: "Additional HCC26 Value",
          width: 60,
        };
        break;
      case "additionalhcc27":
        colDef = {
          ...colDef,
          headerName: "Additional HCC27",
          headerTooltip: "Additional HCC27",
          width: 60,
        };
        break;
      case "additional_hcc27_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC27 Value",
          headerTooltip: "Additional HCC27 Value",
          width: 60,
        };
        break;
      case "additionalhcc28":
        colDef = {
          ...colDef,
          headerName: "Additional HCC28",
          headerTooltip: "Additional HCC28",
          width: 60,
        };
        break;
      case "additional_hcc28_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC28 Value",
          headerTooltip: "Additional HCC28 Value",
          width: 60,
        };
        break;
      case "additionalhcc29":
        colDef = {
          ...colDef,
          headerName: "Additional HCC29",
          headerTooltip: "Additional HCC29",
          width: 60,
        };
        break;
      case "additional_hcc29_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC29 Value",
          headerTooltip: "Additional HCC29 Value",
          width: 60,
        };
        break;
      case "additionalhcc30":
        colDef = {
          ...colDef,
          headerName: "Additional HCC30",
          headerTooltip: "Additional HCC30",
          width: 60,
        };
        break;
      case "client_approval_sent_date":
        colDef = {
          ...colDef,
          headerName: "Client Approval Sent Date",
          headerTooltip: "Client Approval Sent Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "client_approval_receive_date":
        colDef = {
          ...colDef,
          headerName: "Client Approval Receive Date",
          headerTooltip: "Client Approval Receive Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 90,
        };
        break;
      case "client_approval_name":
        colDef = {
          ...colDef,
          headerName: "Client Approval Name",
          headerTooltip: "Client Approval Name",
          width: 70,
        };
        break;
      case "client_submission_order":
        colDef = {
          ...colDef,
          headerName: "Client Submission Order",
          headerTooltip: "Client Submission Order",
          width: 70,
        };
        break;
      case "additional_hcc30_value":
        colDef = {
          ...colDef,
          headerName: "Additional HCC30 Value",
          headerTooltip: "Additional HCC30 Value",
          width: 60,
        };
        break;
      case "importdate_open":
        colDef = {
          ...colDef,
          headerName: "Import Date Open",
          headerTooltip: "Import Date Open",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "importdate_retrieved":
        colDef = {
          ...colDef,
          headerName: "Import Date Retrieved",
          headerTooltip: "Import Date Retrieved",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "days_open":
        colDef = {
          ...colDef,
          headerName: "Days Open",
          headerTooltip: "Days Open",
          width: 75,
        };
        break;
      case "recordkey":
        colDef = {
          ...colDef,
          headerName: "Record Key",
          headerTooltip: "Record Key",
          width: 80,
        };
        break;
      case "membergender":
        colDef = {
          ...colDef,
          headerName: "Member Gender",
          headerTooltip: "Member Gender",
          width: 70,
        };
        break;
      case "begin_dos":
        colDef = {
          ...colDef,
          headerName: "Begin DOS",
          headerTooltip: "Begin DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "end_dos":
        colDef = {
          ...colDef,
          headerName: "End DOS",
          headerTooltip: "End DOS",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "hcc_combo":
        colDef = {
          ...colDef,
          headerName: "HCC Combo",
          headerTooltip: "HCC Combo",
          width: 80,
        };
        break;
      case "overall_result":
        colDef = {
          ...colDef,
          headerName: "Overall Result",
          headerTooltip: "Overall Result",
          width: 70,
        };
        break;
      case "submission_order":
        colDef = {
          ...colDef,
          headerName: "Submission Order",
          headerTooltip: "Submission Order",
          width: 60,
        };
        break;
      case "provider_sign":
        colDef = {
          ...colDef,
          headerName: "Provider Sign",
          headerTooltip: "Provider Sign",
          width: 70,
        };
        break;
      case "provider_cred":
        colDef = {
          ...colDef,
          headerName: "Provider Cred",
          headerTooltip: "Provider Cred",
          width: 70,
        };
        break;
      case "emrflag":
        colDef = {
          ...colDef,
          headerName: "EMR Flag",
          headerTooltip: "EMR Flag",
          width: 70,
        };
        break;
      case "emrdate":
        colDef = {
          ...colDef,
          headerName: "EMR Date",
          headerTooltip: "EMR Date",
          cellClass: "isDate",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "emrdateverified":
        colDef = {
          ...colDef,
          headerName: "EMR Date Verified",
          headerTooltip: "EMR Date Verified",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "follow_up":
        colDef = {
          ...colDef,
          headerName: "Follow Up",
          headerTooltip: "Follow Up",
          width: 70,
        };
        break;
      case "v12_member_hcc_count":
        colDef = {
          ...colDef,
          headerName: "V12 Member HCC Count",
          headerTooltip: "V12 Member HCC Count",
          width: 60,
        };
        break;
      case "v12_valid_hccs":
        colDef = {
          ...colDef,
          headerName: "V12 Valid HCCs",
          headerTooltip: "V12 Valid HCCs",
          width: 60,
        };
        break;
      case "v12_non_valid_hccs":
        colDef = {
          ...colDef,
          headerName: "V12 Non Valid HCCs",
          headerTooltip: "V12 Non Valid HCCs",
          width: 60,
        };
        break;
      case "v12_validates_to_higher_hcc":
        colDef = {
          ...colDef,
          headerName: "V12 Validates to Higher HCC",
          headerTooltip: "V12 Validates to Higher HCC",
          width: 60,
        };
        break;
      case "v12_may_validate":
        colDef = {
          ...colDef,
          headerName: "V12 May Validate",
          headerTooltip: "V12 May Validate",
          width: 60,
        };
        break;
      case "v12_validates_to_a_lower_hcc":
        colDef = {
          ...colDef,
          headerName: "V12 Validates to a Lower HCC",
          headerTooltip: "V12 Validates to a Lower HCC",
          width: 60,
        };
        break;
      case "sourcekey":
        colDef = {
          ...colDef,
          headerName: "Source Key",
          headerTooltip: "Source Key",
          width: 70,
        };
        break;
      case "v12_hccs_pending_validation_results":
        colDef = {
          ...colDef,
          headerName: "V12 HCCs Pending Validation Results",
          headerTooltip: "V12 HCCs Pending Validation Results",
          width: 60,
        };
        break;
      case "v12_validation_rate":
        colDef = {
          ...colDef,
          headerName: "V12 Validation Rate",
          headerTooltip: "V12 Validation Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
        };
        break;
      case "v22_member_hcc_count":
        colDef = {
          ...colDef,
          headerName: "V22 Member HCC Count",
          headerTooltip: "V22 Member HCC Count",
          width: 60,
        };
        break;
      case "v22_valid_hccs":
        colDef = {
          ...colDef,
          headerName: "V22 Valid HCCs",
          headerTooltip: "V22 Valid HCCs",
          width: 60,
        };
        break;
      case "v22_non_valid_hccs":
        colDef = {
          ...colDef,
          headerName: "V22 Non Valid HCCs",
          headerTooltip: "V22 Non Valid HCCs",
          width: 60,
        };
        break;
      case "v22_validates_to_higher_hcc":
        colDef = {
          ...colDef,
          headerName: "V22 Validates to Higher HCC",
          headerTooltip: "V22 Validates to Higher HCC",
          width: 60,
        };
        break;
      case "v22_may_validate":
        colDef = {
          ...colDef,
          headerName: "V22 May Validate",
          headerTooltip: "V22 May Validate",
          width: 60,
        };
        break;
      case "v22_validates_to_a_lower_hcc":
        colDef = {
          ...colDef,
          headerName: "V22 Validates to a Lower HCC",
          headerTooltip: "V22 Validates to a Lower HCC",
          width: 60,
        };
        break;
      case "v22_hccs_pending_validation_results":
        colDef = {
          ...colDef,
          headerName: "V22 HCCs Pending Validation Results",
          headerTooltip: "V22 HCCs Pending Validation Results",
          width: 60,
        };
        break;
      case "v22_validation_rate":
        colDef = {
          ...colDef,
          headerName: "V22 Validation Rate",
          headerTooltip: "V22 Validation Rate",
          valueFormatter: (params) => formatPercentage(params.value),
          width: 70,
        };
        break;
      case "datesubmitted":
        colDef = {
          ...colDef,
          headerName: "Date Submitted",
          headerTooltip: "Date Submitted",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "acceptdate":
        colDef = {
          ...colDef,
          headerName: "Date Accepted",
          headerTooltip: "Date Accepted",
          cellClass: "isDTime",
          valueFormatter: (params) => formatDateUTC(params.value),
          width: 100,
        };
        break;
      case "acceptedby":
        colDef = {
          ...colDef,
          headerName: "Accepted By",
          headerTooltip: "Accepted By",
          width: 70,
        };
        break;
      default:
        break;
    }
    return colDef;
  });
  return columnDefs;
};

const resProc = (resj) => {
  if (resj && resj.rows && resj.rows.length > 0) {
    let forReturn = [...resj.rows];
    // let columnDefs=columnsFactory(forReturn[0])
    return { rowData: forReturn, columnDefs: columnsFactory(forReturn[0]) };
  } else {
    return { rowData: [], columnDefs: [] };
  }
};

const grouper = (headerName, bigColumnDefs, desiredChildrenArray) => {
  let forReturn = {
    headerName: headerName,
    headerTooltip: headerName,
    children: [],
  };
  _.each(desiredChildrenArray, (child, i) => {
    if (_.findWhere(bigColumnDefs, { field: child })) {
      forReturn.children.push(_.findWhere(bigColumnDefs, { field: child }));
    }
  });
  return forReturn;
};

const getGridSort = (columnState) => {
  let ans = [];
  columnState.forEach((el) => {
    if (el.sort) {
      ans.push({ colId: el.colId, sort: el.sort, sortIndex: el.sortIndex });
    }
  });
  return ans;
};

const capitalizeFirstChar = (params) => {
  if(params) {
    let str = params.toLowerCase();
        str = str.charAt(0).toUpperCase() + str.slice(1)
    return str;
  } else {
    return "";
  }
}

export {
  formatPhoneNumber,
  formatDate,
  formatDateUTC,
  formatDateTime,
  formatTime,
  colSequencer,
  resProc,
  grouper,
  passFailColor,
  getGridSort,
  capitalizeFirstChar,
  decrypt,
  encrypt,
  generateToken
};
