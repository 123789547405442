import React, {useState, useEffect} from "react";
import { Navbar, Nav, Container, Table, NavDropdown, Alert, Button, Row, Col,Modal } from "react-bootstrap"; //Form, FormControl, Button,
// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
//import { LinkContainer } from "react-router-bootstrap";
import moment from "moment-timezone";
import "./NavBarTop.css";
// import {Envelope, Bell} from "react-bootstrap-icons"
// import {Badge} from "@material-ui/core"
//import { LinkContainer } from "react-router-bootstrap";
import TimeClock from '../containers/TimeClock/TimeClock'
import CountDown from '../containers/CountDown/CountDown';
import { Rating } from "@mui/material";
import localStorage from "react-secure-storage";

const NavBarTop = (props) => {
  let clients = localStorage.getItem("clients") ? localStorage.getItem("clients") : ''
  let defaultClient = ['UHC']
  let clientArr = clients.split(",")
  console.log(defaultClient)
  console.log(clientArr)
  let showUhcImage = clients ? JSON.stringify(clientArr) === JSON.stringify(defaultClient) ? true : false : true
  console.log(showUhcImage)
  
  let markets = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).markets ? JSON.parse(localStorage.getItem('user')).markets : '' : ''
  let nyMarket = ['NY']
  let marketArr = markets.split(",")
  console.log('New York Market:', nyMarket)
  console.log('User Markets:', marketArr)
  let showNYQuickStepsGuide = JSON.stringify(marketArr) === JSON.stringify(nyMarket) ? true : false
  console.log('Show NY Quick Steps Guide:', showNYQuickStepsGuide)

  const [showAlert, setShowAlert] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState({});
  const [navVersion, setNavVersion] = useState(0); // Counter for navigation screen changes
  const [broadcastParams, setBroadcastParams] = useState({})
  const [clockStartTime, setClockStartTime] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rate, setRate] = useState(localStorage.getItem("appRating"));

  useEffect(() => {
    setTimeout(function () {
        if (!localStorage.getItem("appRating")) {
            handleShow();
        }
    }, 180000);
}, []);

useEffect(() => {
    if (!localStorage.getItem("token")) {
        alert('We apologize for the inconvenience. We encountered an issue while authorizing your login information. As a result, you will be automatically redirected back to the login screen. If you continue to experience this issue, please contact Optum Support at (855) 819-5909 or via  optumsupport@optum.com')
        localStorage.clear()
        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
        return;
    }
}, []);

  const handleBroadcastMessage = async (broadcast) => {
    console.log(broadcast)
    let message;
    let startDT = broadcast.startDateTime
    let endDT = broadcast.endDateTime

    startDT = startDT.replace('-07:00','');
    endDT = endDT.replace('-07:00','');

    console.log(startDT)
    console.log(endDT)
    let startDate = moment(startDT).tz('America/Los_Angeles', true).format('MM/DD/YYYY h:mm a')
    let endDate = moment(endDT).tz('America/Los_Angeles', true).format('MM/DD/YYYY h:mm a')

    console.log(startDate)
    console.log(endDate)
    let startDatePST = moment(startDate).tz('America/Los_Angeles', true)
    console.log(startDatePST)

   switch (broadcast.msgType) {
     case "maintenance":
       message = (
         <div style={{ textAlign: "center", width: "50%" }}>
           <h5 style={{ margin: 10 }}>
             <u>System Maintenance</u>
           </h5>
           <p style={{ margin: 10 }}>
             DMS will be performing system maintenance between
           </p>
           <p>
             <span style={{ color: "red" }}>{startDate} </span>
             and
             <span style={{ color: "red" }}> {endDate}</span>
            {" "} PST
           </p>
           <p style={{ margin: 10 }}>
              During this time, users will be unable to access the application.
              Scheduled communications will be sent once the maintenance period ends.
            </p>
         </div>
       );
       break;
     default:
       break;
   }

    let startTimeLocal = moment(new Date(startDatePST)).format("h:mm");
    console.log(startTimeLocal)
    let start = moment(new Date());
    let end = moment(startDatePST);
    let duration = moment.duration(end.diff(start));
    let milliSeconds = duration.asMilliseconds();

   let broadcastParams = {
       timerDuration: milliSeconds,
       startDateTime: moment(broadcast.startDateTime).format('MM/DD/YYYY h:mm a'),
       endDateTime: moment(broadcast.endDateTime).format('MM/DD/YYYY h:mm a'),
       viewType: broadcast.viewType,
       message: message,
       _id: broadcast._id
   }
   await setBroadcastParams(broadcastParams)
   await setShowAlert(true);
   await setClockStartTime(startTimeLocal)
  
  //  setTimeout(async () => {
  //   await formatClockStyle(startTime)
  //  }, 1000);
      };

 const runBroadcastCheck = async( ) => {
   // Logic to check if maintenance alert should display
   // ... logic ...
   let shouldShowAlert = false; // Set to false for now
   let currentTime = moment(new Date()).tz('America/Los_Angeles').format("YYYY-MM-DD[T]HH:mm:ss");
        console.log(currentTime)
       try {
         let res = await fetch(localStorage.getItem("apiUrl") +  "/getBroadcastCheck?dateTime=" + currentTime, 
          { headers: new Headers({ "Content-Type": "application/json" }) 
        });
         let resj = await res.json();
         if (resj.rows) {
           let forReturn = [...resj.rows];
           if(forReturn.length > 0) {
           let broadcast = forReturn[0];
           console.log(broadcast)
           let usersAccepted = broadcast.userOk
           console.log(usersAccepted)
           if(!usersAccepted.includes(localStorage.getItem('u')) && broadcast.envType !== 'internal') {
               shouldShowAlert = true
               handleBroadcastMessage(broadcast)
               // setBroadcastMessage(broadcast)
           } else {
               setShowAlert(false);
           }
       }
          else {
           setShowAlert(false);
         }
       }
       } catch (e) {
         console.log(e);
       }
       console.log('yo here i am')
   // // This will show the alert on this component
   // setShowAlert(shouldShowAlert);

   // Notify the parent component about the alert status, adjusting height if necessary
   // props.alertIsVisible(shouldShowAlert);
   }

   const handleCloseAlert = () => {
    // Close alert
    let forPosting={
        u: localStorage.getItem('u'),
        _id: broadcastParams._id
      }
    console.log(`starting the post for ${JSON.stringify(forPosting)}`)
 
    fetch(localStorage.getItem("apiUrl") + "/putBroadcastAccept", {
        "method" : "put",
        "headers": new Headers({'Content-Type':'application/json'}),
        "body": JSON.stringify(forPosting)
    })
    .then(res=>res.json())
    .then(res=>{
        console.log(res)
    })
    .catch(console.log)

    setShowAlert(false);
    // Notify parent that alert is closed
}

  useEffect( () => {
    runBroadcastCheck()           
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navVersion]);

  useEffect( () => {
    runBroadcastCheck()           
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.navVersion]);



  // let history = useHistory();
  const handleLogoff = () => {
    // post to 6cLogging here for the logoff event
    // let userid = localStorage.getItem("u");
    let redirectUrl = showUhcImage ? `https://uhcprovider.com` : process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login'
    props.location.search = null
    let forPosting = {
      product: "JadeXPlus",
      class: "SECURITY_AUDIT",
      sourceUserMSID: localStorage.getItem('u'),
      message: "TerminateUserSession:SUCCESS",
      severity: "INFO",
    };
    console.log(`starting the post for ${JSON.stringify(forPosting)}`);
    // console.log(localStorage.getItem("authUrl"));

    fetch(`${localStorage.getItem("apiUrl")}/post6c`, {
      method: "post",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(forPosting),
    })
      .then((res) => {
        console.log(res);
        localStorage.clear();
        window.location.assign(redirectUrl)
      })
      .catch(console.log);
  };
  let reverseName, nameToDisplay
  if (localStorage.getItem("displayName")) {
      if (localStorage.getItem("displayName").includes(',')) {
        reverseName=localStorage.getItem("displayName").split(',')
        nameToDisplay=`${reverseName[1]} ${reverseName[0]}`
      }else{
        nameToDisplay=localStorage.getItem("displayName")
      }
  }

  const faqView=()=>{
    // COM FAQ
    // https://optum.com/content/dam/internal-resources/pdfs/optum-medicaid-provider-claim-review-commercial-faqs.pdf
    if (clientArr.includes("UHC")) {
      window.open(`https://www.optum.com/content/dam/internal-resources/pdfs/optum-medicaid-cs-provider-claim-review-faqs.pdf`, 'FAQViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    } else {
      window.open(`https://optum.com/content/dam/internal-resources/pdfs/optum-medicaid-provider-claim-review-commercial-faqs.pdf`, 'FAQViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    }
    // window.open(`https://optum.com/content/dam/optum3/optum/en/resources/fact-sheets/optum-medicaid-provider-claim-review-faqs-interactive.pdf`, 'FAQViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    //  window.open(`${process.env.PUBLIC_URL}/optum-pcr-ref-sheet.pdf`, 'FAQViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const userGuideView=()=>{
    window.open(`https://optum.com/content/dam/internal-resources/pdfs/provider-claim-review-user-guide.pdf`, 'UserGuideViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    // window.open(` https://optum.com/content/dam/optum3/optum/en/resources/guides/provider-claim-review-user-guide.pdf`, 'UserGuideViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  //  window.open(`${process.env.PUBLIC_URL}/optum-pcr-user-guide.pdf`, 'UserGuideViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const quickStepsView=()=>{
      // window.open(`https://optum.com/content/dam/optum4/resources/pdf/optum-pcr-quick-steps-to-review-a-claim.pdf` , 'QuickStepsViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
      //  window.open(`https://optum.com/content/dam/optum4/resources/pdf/optum-pcr-quick-steps-to-review-a-claim.pdf`, 'QuickStepsViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
       window.open(`https://www.optum.com/content/dam/internal-resources/pdfs/optum-pcr-quick-steps-to-review-a-claim.pdf`, 'QuickStepsViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const quickStepsViewNY=()=>{
      window.open(`${process.env.PUBLIC_URL}/optum-pcr-quick-steps-NY.pdf`, 'QuickStepsViewerNY', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const acctSetupView=()=>{
    window.open(`https://optum.com/content/dam/optum4/resources/pdf/provider-claim-review-account-setup-form.pdf`, 'AcctSetupViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  //  window.open(`${process.env.PUBLIC_URL}/optum-pcr-quick-steps.pdf`, 'QuickStepsViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const reimbursementGuideView=()=>{
    window.open(`https://optum.com/content/dam/optum4/resources/pdf/provider-claim-review-program-reimbursement.pdf`, 'ReimbursementGuideViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  //  window.open(`${process.env.PUBLIC_URL}/optum-pcr-quick-steps.pdf`, 'QuickStepsViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const paymentScreenGuideView=()=>{
    window.open(`https://optum.com/content/dam/optum4/resources/pdf/pcr-payment-user-guide.pdf`, 'PaymentScreenGuideViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const termsOfUseView=()=>{
    window.open(`${process.env.PUBLIC_URL}/optum-pcr-terms-of-use.pdf`, 'TermsOfUseViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }

  const privacyPolicyView=()=>{
    window.open(`${process.env.PUBLIC_URL}/optum-pcr-privacy-policy.pdf`, 'PrivacyPolicyViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
  }


  const handleChangeRate = (e, newValue) => {
    setRate(newValue);
    if (newValue && newValue != 0) {
        localStorage.setItem("appRating", newValue);
        let forPosting = { user: localStorage.getItem("u"), name: localStorage.getItem("displayName"),appName: 'Jade External PCR', rating: newValue, dateTime: new Date() };
        fetch(localStorage.getItem("apiUrl") + "/postRating", {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(forPosting),
            redirect: "follow",
        })
            .then((r) => {
                handleClose();
            })
            .catch(console.log);
    }
};

  return (
<div className="container-fluid fixed-top p-0">
<Alert
                  show={showAlert}
                  onClose={() => handleCloseAlert()} 
                  variant={"danger"}
                  style={{
                    padding: "0",
                    margin: 0,
                    width: "100%",
                    // border: "solid #343a40",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",textAlign: 'center'
                      }}
                    >
                     <p style={{marginTop: 10, marginBottom: broadcastParams.viewType !== "timer" && broadcastParams.viewType !==  'both' ?  0 : 10 }}>Local time remaining until maintenance:</p>
                      <div style={{ display: "flex",
                        justifyContent: "center",
                        alignItems: "center",}}>
                      {broadcastParams.viewType !== "timer" && clockStartTime !== null ? <TimeClock startTime={clockStartTime}/> : null}
                      {broadcastParams.viewType !== "clock" ? (
                        <CountDown
                          duration={broadcastParams.timerDuration}
                        ></CountDown>
                      ) : null}
                    </div>
                    </div>
                    {broadcastParams.message}
                    <div
                      style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="success"
                        style={{
                          border: "solid 1px black",
                          width: "100px",
                          color: "white",
                        }}
                        onClick={handleCloseAlert} 
                      >
                        Got it
                      </Button>
                    </div>
                  </div>
                </Alert>
<Navbar
      variant="light"
      // fixed="top"
      style={{ padding: "2px", margin: "0px", backgroundColor: "white" }}
    >
      <Navbar.Brand href="http://optum.com" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "white" }}>
        &ensp;&ensp;
        <img
          src={process.env.PUBLIC_URL + "/optum_2x.png"}
          height="32px"
          alt="Optumlogo"
          // localStorage.getItem("appDescription") +
          title={
            localStorage.getItem("appDescription") +
            " v" +
            localStorage.getItem("appVersion")
          }
        />
      </Navbar.Brand>
      <Nav className="mr-auto" variant="pills">
        {/* <Nav.Link href="/dashboard">Dashboard</Nav.Link> */}
        {/* <Nav.Link href="/packages">Provider</Nav.Link> */}
        {/* <Nav.Link href="/single">SingleTIN</Nav.Link> */}
        {/*  <Nav.Link href="/super">SuperMultiTIN</Nav.Link> */}
        {/* <Nav.Link href="/portalDash" pill>PortalDash</Nav.Link> */}
        {/* <Nav.Link href="/dashboard">Dashboard</Nav.Link> */}
        {/* <Nav.Link href="/dash">DashCMS</Nav.Link> */}
        {/* <NavDropdown title="All" id="basic-nav-dropdown">
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'all'}}} style={{textDecoration:'none'}}><NavDropdown.Item >All Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'allOpen'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Open  Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'allAccepted'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Accepted Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'allRejected'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Rejected Claims</NavDropdown.Item></LinkContainer>
        </NavDropdown> */}
        {/* <NavDropdown title="Adjusted" id="basic-nav-dropdown">
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'adjusted'}}} style={{textDecoration:'none'}}><NavDropdown.Item >All Adjusted Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'adjustedOpen'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Open Adjusted Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'adjustedAccepted'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Accepted Adjusted Claims</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'adjustedRejected'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Rejected Adjusted Claims</NavDropdown.Item></LinkContainer>
        </NavDropdown> */}
        {/* <NavDropdown title="Aged" id="basic-nav-dropdown">
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'aged'}}} style={{textDecoration:'none'}}><NavDropdown.Item >All Aged</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'days7older'}}} style={{textDecoration:'none'}}><NavDropdown.Item >7 days older</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'days14older'}}} style={{textDecoration:'none'}}><NavDropdown.Item >14 days older</NavDropdown.Item></LinkContainer>
        </NavDropdown> */}
        {/* <NavDropdown title="Preferences" id="basic-nav-dropdown">
          
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'days7older'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Show/Hide Packages</NavDropdown.Item></LinkContainer>
          <LinkContainer to={{pathname:'/portalDash', state:{filter:'days14older'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Show/Hide Providers</NavDropdown.Item></LinkContainer>
        </NavDropdown> */}
        <NavDropdown title="Resources" id="nav-dropdown">
          {/* <LinkContainer to={{pathname:'/portalDash', state:{filter:'aged'}}} style={{textDecoration:'none'}}><NavDropdown.Item >Show/Hide Totals Bar</NavDropdown.Item> </LinkContainer> */}
          {/* <LinkContainer to='/userGuide' style={{textDecoration:'none'}}><NavDropdown.Item >PCR User Guide&nbsp;</NavDropdown.Item></LinkContainer> */}
          {/* <LinkContainer to='/faq' style={{textDecoration:'none'}}><NavDropdown.Item >FAQ</NavDropdown.Item></LinkContainer> */}
          <NavDropdown.Item target="_blank" onClick={faqView} >FAQ&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</NavDropdown.Item>
          <NavDropdown.Item target="_blank" onClick={userGuideView} >PCR User Guide&nbsp;</NavDropdown.Item>
          {showNYQuickStepsGuide ?
          null :
          <NavDropdown.Item target="_blank" onClick={quickStepsView}>Quick Steps&nbsp;</NavDropdown.Item>
          }
          {marketArr.includes('NY') ?
          <NavDropdown.Item target="_blank" onClick={quickStepsViewNY}>NY Quick Steps&nbsp;</NavDropdown.Item>
          : null
          }
          {JSON.parse(localStorage.getItem('userPayEligible'))?
          <NavDropdown.Item target="_blank" onClick={acctSetupView} >Account Setup Form&nbsp;</NavDropdown.Item> :null}
          {JSON.parse(localStorage.getItem('userPayEligible'))?
          <NavDropdown.Item target="_blank" onClick={reimbursementGuideView} >Reimbursement Quick Guide&nbsp;</NavDropdown.Item>
          :null}
           {JSON.parse(localStorage.getItem('userPayEligible'))?
          <NavDropdown.Item target="_blank" onClick={paymentScreenGuideView} >Payment Screen Guide&nbsp;</NavDropdown.Item>
          :null}
          
          {/* <NavDropdown.Item target="_blank" onClick={termsOfUseView} >Terms of Use&nbsp;&nbsp;&nbsp;</NavDropdown.Item>
          <NavDropdown.Item target="_blank" onClick={privacyPolicyView} >Privacy Policy&nbsp;</NavDropdown.Item> */}
        </NavDropdown>
        <Nav.Link onClick={termsOfUseView}>
          Terms&nbsp;of&nbsp;Use
        </Nav.Link>
        <Nav.Link onClick={privacyPolicyView}>
          Privacy
        </Nav.Link>
        <Nav.Link onClick={handleShow}>
          Rate Application
        </Nav.Link>
        <Nav.Link onClick={handleLogoff}>
          Logoff
        </Nav.Link>
        {/* {process.env.REACT_APP_ENV == 'Prod' || process.env.REACT_APP_ENV == 'Stg' ? null :
        <Nav.Link onClick={props.use4GHandler}>
          {props.use4G ? "Use Orginal" : "Use 4G"}
        </Nav.Link>
        } */}
      
        {/* <NavDropdown title="SMS" id="SMS-nav-dropdown">
                        <NavDropdown.Item href="/smsappusagestats">App Usage Statistics</NavDropdown.Item>
                        <NavDropdown.Item href="/smssubs">Subscriptions </NavDropdown.Item>
                        <NavDropdown.Item href="/subsByService">Subscriptions by Service</NavDropdown.Item>
                        <NavDropdown.Item href="/smsstakeholders">Stakeholders</NavDropdown.Item>
                        <NavDropdown.Item href="/smssubscriberstakeholders">Subscriber by Stakeholders</NavDropdown.Item>
                        <NavDropdown.Item href="/smsstakeholdersubscriber">Stakeholder by Subscription</NavDropdown.Item>
                        <NavDropdown.Item href="/smsSubscriptionServers">Subscription Servers</NavDropdown.Item>
                        <NavDropdown.Item href="/smschroniclerevents">Chronicler Events</NavDropdown.Item>
                        <NavDropdown.Item href="/smsjiraevents">JIRA Events</NavDropdown.Item>
                        <NavDropdown.Item href="/smsjiraeventsmonthly">JIRA Events by Month</NavDropdown.Item>
                    </NavDropdown> */}
        {/* <NavDropdown title="Rundeck" id="Rundeck-nav-dropdown">
                        <NavDropdown.Item href="/rdExecutionsByProject">Executions By Project </NavDropdown.Item>
                        <NavDropdown.Item href="/rdExecutionsByProjectYear">Executions By Project By Year </NavDropdown.Item>
                    </NavDropdown> */}
        {/* <NavDropdown title="Bitbucket" id="Bitbucket-nav-dropdown">
                        <NavDropdown.Item href="/bbJadeCommits">Jade commits </NavDropdown.Item>
                        <NavDropdown.Item href="/bbRadvCommits">RADV Commits</NavDropdown.Item>
                    </NavDropdown> */}
        {/* <NavDropdown title="Nuxeo" id="Nuxeo-nav-dropdown"> */}
        {/* <NavDropdown.Item href="/nxBrowser">Nuxeo Browser </NavDropdown.Item> */}
        {/* <NavDropdown.Item href="/rdExecutionsByProjectYear">Executions By Project By Year </NavDropdown.Item> */}
        {/* </NavDropdown> */}
        {/* <Nav.Link href="/providerSearch">ProviderSearch</Nav.Link> */}
        {/* <Nav.Link href="/sample">Sample</Nav.Link>
                    <Nav.Link href="/scratch">Scratch</Nav.Link> */}
      </Nav>
      {/* &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; */}
      <Container
        className="justify-content-end"
        style={{ paddingRight: "0px", marginRight: "0px", width: '475px' }}
      >
        <Nav fill>
        {/* <Nav.Link>
            <Badge badgeContent={localStorage.getItem('readMessage')  === '0'  ? props.messageCount : 0} onClick={props.showMessagesHandler} title='Messages' color="secondary">
                <Envelope className='mr-sm-2' onClick={props.showMessagesHandler} size={20} />
            </Badge>
        </Nav.Link>
        <Nav.Link>
            <Badge badgeContent={localStorage.getItem('readAlert')  === '0'  ? props.alertCount : 0} onClick={props.showAlerts} title='Alerts' color="secondary">
                <Bell className='mr-sm-2' size={20} onClick={props.showAlerts}/>
            </Badge>
        </Nav.Link> */}
          <Nav.Item>
            <Table size='sm' borderless style={{margin:'0px', padding:'0px', textAlign:'right', lineHeight:'1em'}}>
              <tbody>
                <tr>
                    <td><small>Welcome {nameToDisplay || null} &nbsp; {process.env.REACT_APP_ENV!=='Prod'?localStorage.getItem('u'):null}</small></td>
                </tr>
                <tr>
                    <td><small>{moment().format("dddd, MMMM D, YYYY h:mm A")}</small></td>
                </tr>
                </tbody>
            </Table>
          </Nav.Item>
          <Nav.Item>
            <Navbar.Brand href="https://www.uhc.com" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "white" }}>
              &emsp;
              {showUhcImage ?
                  <img
                    src={process.env.PUBLIC_URL + "/UHCCommunity.png"}
                    height="46px"
                    alt="UHC logo"
                  />
                : null
              }
            </Navbar.Brand>
          </Nav.Item>
        </Nav>
      </Container>
</Navbar>
  <Modal show={show} size="sm" onHide={handleClose} centered={true}>
            <Modal.Header si style={{ color: "var(--optum-orange)", textAlign: "center" }} closeButton>
                <Modal.Title style={{ width: "100%" }}>App Rating</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ textAlign: "center" }}>
                    <p>Please rate your app experience.</p>
                    <Rating name="simple-controlled" size="large" value={parseInt(rate)} onChange={handleChangeRate} />
                </div>
            </Modal.Body>
        </Modal>
    
    </div>
  );
};

export default withRouter(NavBarTop);


