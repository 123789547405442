import React, { Component } from 'react'
import { Container, Row, Col, Button,  Modal, InputGroup, FormControl} from 'react-bootstrap';
import './Messaging.css';
import MessageInbox from './MessageInbox';
import _ from 'lodash';
import moment from 'moment-timezone';
import MessageResponses2 from './MessageResponses2';
import {Paper, TextField} from '@mui/material';
import localStorage from "react-secure-storage";

/**
 * @class Messaging
 * @descr Display the Messages
 * @version 1.0
 * @param groupid
 * @returns all components for this screen: MessageInbox and Providers
 *
 */

 const textStyle={fontWeight: 'bold', paddingTop: 0, paddingBottom: 5}
 const inputText= {height:'29px',width:'86px', fontWeight:'bold'}

 class Messaging extends Component {
    childINBOX= React.createRef(); // MessageInbox
    state = { isLoading:false,
        showModal1:false,
        inboxVersion:1,
        replyVersion:1,
        responseVer: 1,
        userid:localStorage.getItem('userkey'),
        useremail:localStorage.getItem('useremail'),
        displayName:localStorage.getItem('displayName'),
        client:localStorage.getItem('selectedClient')||'UHC',
        program:localStorage.getItem('selectedProgram'),
        loading:false,
        _id:null,
        replyText:'',
        original: [],
        toId: 'system',
        toName: 'Outreach Operations',
    } 

    componentDidMount () {
        // console.log(this.state)
        const local=localStorage.getItem('MessagingState')
        //console.log(local)
        const rehydrate = JSON.parse(local)
        //console.log(rehydrate)
        if(rehydrate)
            {   
                this.setState(rehydrate)
            }
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log(prevState)
        //console.log(this.state)
    }

    CurDateYYYY_MM_DD () {
        let utcMoment = moment.utc();
        let yyyymmdd=utcMoment.format()
        return yyyymmdd
    }

    clickRefresh = _.debounce(
        () => {
        this.setState({inboxVersion:this.state.inboxVersion +1})  // re-render
        this.setState({replyVersion:this.state.replyVersion +1})  // re-render
        }
        , 500); 

    clickClearFilters = _.debounce(
        () => {
    //        console.log('clickClearFilters')
            if(this.childINBOX.current) {
                this.childINBOX.current.clearFilter()
            }
        }
        , 500);

    changeMessageHandler = (row) => {
        console.log(row)
            this.setState({_id:row._id, responseVer: this.state.responseVer + 1, original: row, subject: row.subject})
            this.modal1Hide()
    }    
        
    replyHandler = (_id) => {
        if (this.state._id) {
            console.log('RHandler '+this.state._id)
            this.setState({showModal1:true,replyText:'', showModal2: false})
        }
    }

    sendMessageHandler = (_id) => {
            this.setState({showModal2:true,replyText:'', subject1: '', showModal1: false})
    }



    handleReplyChange = (e) => {
        this.setState({replyText: e.target.value});
    };

    modal1Send = () => {
        this.callAPIReply()
        this.callAPIResponded()
        //this.setState({showModal1:false,replyText:''})
    }

    resolveMessage = () => {
        if (this.state._id) {
            let parm1 = {
                messageId : this.state._id
            }

        fetch(localStorage.getItem('apiUrl')+'/putResolveMessage', {
            "method" : "post",
            "headers": new Headers({'Content-Type':'application/json'}),
            "body": JSON.stringify(parm1)
        })
        .then(res=>res.json())
        .then(res=>{
            console.log(res)
                this.setState({showModal2:false,replyText:'', inboxVersion: this.state.inboxVersion + 1, responseVer: this.state.responseVer + 1, showModal1: false})
                this.props.loadMessageCount()
        })
     }
    }

    callAPIReply = () =>{
        this.setState({isLoading: true})

        // let res={
		// 	fromId:msg.fromId,
		// 	fromName:msg.fromName,
		// 	fromEmail:msg.fromEmail,
		// 	toId:msg.toId,
		// 	toName:msg.toName,
		// 	toEmail:msg.toEmail,
		// 	subject:origMessage.subject,
		// 	message:msg.message,
		// 	dateTime:new Date(),
		// 	status:'unread',
		// 	intExt: msg.intExt
		// }

        let parm1={
            originalMessageId:this.state._id,
            fromId:this.state.userid,
            fromName:this.state.displayName, 
            fromEmail:this.state.useremail, 
            toId: this.state.toId,
            toName: this.state.toName,
            toEmail: 'test@optum.com',
            message:this.state.replyText,
            intExt: 'E'}

            console.log(parm1)

        fetch(localStorage.getItem('apiUrl')+'/putResponseMessage', {
            "method" : "post",
            "headers": new Headers({'Content-Type':'application/json' }),
            "body": JSON.stringify(parm1)
        })
        .then(res=>res.json())
        .then(res=>{
            console.log(res)
                this.setState({showModal1:false,replyText:'', inboxVersion: this.state.inboxVersion + 1, responseVer: this.state.responseVer + 1})
        })
    }

    callAPISendNewMessage = () =>{
        let parm1 = {
            fromId:this.state.userid,
            fromName:this.state.displayName, 
            fromEmail:this.state.useremail, 
            toId: this.state.toId,
            toName: this.state.toName,
            toEmail: "outreach@somewhere.com",
            subject: this.state.subject1,
            message: this.state.replyText
        }

            console.log(parm1)

        fetch(localStorage.getItem('apiUrl')+'/postNewMessage', {
            "method" : "POST",
            "headers": new Headers({'Content-Type':'application/json'}),
            "body": JSON.stringify(parm1)
        })
        .then(res=>res.json())
        .then(res=>{
            console.log(res)
                this.setState({showModal2:false,replyText:'', inboxVersion: this.state.inboxVersion + 1, responseVer: this.state.responseVer + 1, showModal1: false})
        })
    }

    callAPIResponded = () =>{
        this.setState({isLoading: true})

        let parm1={messageId:this.state._id, status: 'Replied'}
        console.log(parm1)

        fetch(localStorage.getItem('apiUrl')+'/putMarkMessage', {
            "method" : "post",
            "headers": new Headers({'Content-Type':'application/json'}),
            "body": JSON.stringify(parm1)
        })
        .then(res=>res.json())
        .then(res=>{ 
            console.log(res)           
            this.props.loadMessageCount()
        }) 

    }

    modal1Hide = () => {
        this.setState({showModal1:false, showModal2: false})
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    refreshHandler = (e) => {
        this.setState({
            responseVer: this.state.responseVer + 1,
            inboxVersion: this.state.inboxVersion + 1,
        })
        this.props.loadMessageCount()
    }

    handleClose = () => {
        this.props.closeHandler()
        this.setState({
            responseVer: 0,
            inboxVersion: 0,
            original: [],
            _id: null
        })
    }

    replyForm = () => {
        if(this.state.showModal1) {
        return (
            <Container fluid style={{height:340, overflow: 'auto',marginLeft: 10, marginTop: 20}}>
            <Paper elevation={3} style={{width: '100%', marginBottom: 10, padding: 5}}>
            {/* <h5>Reply</h5> */}
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>From:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.state.displayName + " <" + this.state.userid + ">"}></TextField>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>To:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle}} variant='outlined' value={this.state.toName + " <" + this.state.toId + ">"}></TextField>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>Subject:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle}} variant='outlined' value={this.state.subject}></TextField>
                </InputGroup>
                <FormControl size="lg" as="textarea" value={this.state.replyText} style={{height:'180px', fontSize: 16, marginTop: 10}} aria-label="With textarea" onChange={this.handleReplyChange}/>
                {/* <div style={{float: 'right'}}> */}
                <button style={{marginTop: 5,marginRight: 5}} onClick={this.modal1Send}>Send</button>
                <button style={{marginTop: 5}} onClick={this.modal1Hide}>Cancel</button>
                {/* </div> */}
            </Paper>
            </Container>
        )
        }
    }

    newMessageForm = () => {
        if(this.state.showModal2) {
            return (
            <Container fluid style={{height:340, overflow: 'auto',marginLeft: 10}}>
            <Paper elevation={3} style={{width: '100%', marginBottom: 10, padding: 5}}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>From:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '89%', marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.state.displayName + " <" + this.state.userid + ">"}></TextField>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>To:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '89%', marginTop: 5}} id='toName1' inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.state.toName + " <" + this.state.toId + ">"}></TextField>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text size="sm" style={inputText}>Subject:</InputGroup.Text>
                    </InputGroup.Prepend>
                    <TextField style={{width: '89%', marginTop: 5}} id='subject1' inputProps={{style: textStyle}} variant='outlined' value={this.state.subject1} onChange={this.handleChange}></TextField>
                </InputGroup>
                <FormControl size="lg" as="textarea" value={this.state.replyText} style={{height:'180px', fontSize: 16, marginTop: 10, marginBottom: 10}} aria-label="With textarea" onChange={this.handleReplyChange}/>
           
                <button style={{marginTop: 5,marginRight: 5}}  onClick={this.callAPISendNewMessage}>Send</button>
                <button style={{marginTop: 5}}  onClick={this.modal1Hide}>Cancel</button>
            </Paper>
            </Container>
         )
        }
    }

    render() { 


        return ( 
            <Modal size="xl" show={this.props.showMessages} onHide={this.handleClose} dialogClassName="modal-wide">
                <Modal.Header style={{height: 40, padding: 0}}>
                        <Container fluid style={{width:'100%', marginTop: 5}}>
                            <Row style={{height:'35px'}} className=' m-0 p-0'>
                                <Col xs={2} className=' m-0 p-0'>
                                    <h5>Message Inbox</h5>
                                </Col>
                                <Col xs={10} className=' m-0 p-0'>
                                    <Button variant="warning" size="sm" onClick={this.refreshHandler}>Refresh</Button>
                                    &nbsp;
                                    {/* <Button variant="warning" size="sm" onClick={this.sendMessageHandler}>New Message</Button> */}
                                    <Button variant="warning" size="sm" onClick={this.replyHandler}>Reply</Button>
                                    &nbsp;
                                    <Button variant="warning" size="sm" onClick={this.sendMessageHandler}>New Message</Button>
                                    &nbsp;
                                    <Button variant="warning" size="sm" onClick={this.resolveMessage}>Resolve</Button>
                                    &nbsp;
                                    <Button variant="danger" size="sm" style={{float:'right'}} onClick={this.handleClose}>Cancel</Button>
                                </Col>
                            </Row>
                        </Container>
                </Modal.Header>
                <Container fluid style={{width:'98%', marginLeft: 10, marginTop: 10, marginBottom: 10, marginRight: 0, paddingRight:0}}>
                    <Row>
                        <Col xs={6} className='m-0 p-0'>                     
                            <Container fluid className='ml-1 p-0'>
                                <MessageInbox height={900} ref={this.childINBOX}  inboxVersion={this.state.inboxVersion} changeMessage={this.changeMessageHandler} replyClicked={this.replyHandler} />
                            </Container>
                        </Col>
                        <Col xs={6} className='m-0 p-0'>        
                            {this.state.showModal2 ?
                            <>
                            {this.newMessageForm()}
                            </>
                            :
                            <>
                            <MessageResponses2 height={this.state.showModal1 ? 550 : 900} id={this.state._id} responseVersion={this.state.responseVer} original={this.state.original}/>
                            {this.replyForm()}
                            </>
                            }
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Container>
            </Modal>
        );
    }
}
 
export default Messaging;
