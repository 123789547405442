import React, { Component } from "react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import CustomLoadingOverlay from "../components/customLoadingOverlay.jsx";
import CustomNoRowsOverlay from "../components/customNoRowsOverlay.jsx";
// import {nxGetDocById} from '../../utils/nxFunctions';
import {capitalizeFirstChar} from "../utils/layoutUtils"
import moment from "moment-timezone";
import localStorage from "react-secure-storage";

const filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    let dateAsString = cellValue
        dateAsString = dateAsString.slice(0,10)
    if (dateAsString == null) return -1;
    let dateParts = dateAsString.split('-');
    let cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 1900,
  inRangeInclusive: true
};


class PaymentResults extends Component {
  state = {
    rowData: null,
    columnDefs: [
      {
        headerName: "Provider",
        children: [    
        {headerName: "Group Name", field: "groupname_printed_on_claimsform", tooltipField:'groupname_printed_on_claimsform', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:250},
        {headerName: "Group ID", field: "groupid", tooltipField:'groupid', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:100},
        {headerName: "ID", field: "provider_id", tooltipField:'provider_id', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:160},
        {headerName: "Last Name", field: "provider_lastname", tooltipField:'provider_lastname', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:120},
        {headerName: "First Name", field: "provider_firstname", tooltipField:'provider_firstname', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:110},
        {headerName: "Tax ID", field: "tax_id", tooltipField:'tax_id', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:100},
        ]},
        {
        headerName: "Patient",
        children: [    
        {headerName: "ID", field: "member_id", tooltipField:'member_id', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:110},
        {headerName: "Last Name", field: "member_lastname", tooltipField:'member_lastname', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:120},
        {headerName: "First Name", field: "member_firstname", tooltipField:'member_firstname', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:110},
        ]},
      {
        headerName: "Claim",
        children: [
      {headerName: "_id", field: "_id", sortable:true, resizable:true, filter:true, hide:true},
      // {headerName: "Claims Key", field: "claimskey", tooltipField:'claimskey',    cellClass:"textFormat", sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:150},//headerCheckboxSelectionFilteredOnly: true,checkboxSelection: true,headerCheckboxSelection: true,
      {headerName: "Claim ID", field: "claim_id_new", tooltipField:'claim_id_new', cellClass:"textFormat", sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:160},
      {headerName: "Client", field: "clientid", tooltipField:'clientid', cellClass:"textFormat", sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:80},
      // {headerName: "Project ID", field: "projectid", tooltipField:'projectid', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:175},
      // {headerName: "Market", field: "market", tooltipField:'market', cellClass:"textFormat", sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:85},
      // {headerName: "Program", field: "program", tooltipField:'program', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:95},
      {headerName: "Date of Service", field: "dos", tooltipField:'dos', sortable:true, resizable:true,  enableRowGroup:true, enablePivot:true, enableValue:true, width:100, valueFormatter:(params)=>moment(params.value).format('MM/DD/YYYY'), filter: 'agDateColumnFilter', filterParams: filterParams},
      {headerName: "Final Status", field: "final_status", tooltipField:'final_status', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, hide:true, width:160},
    ]},
      // {headerName: "Inventory Key", field: "inventorykey", tooltipField:'inventorykey', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:150},
      // {headerName: "Assigned User", field: "assigneduser", tooltipField:'assigneduser', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:150}, 
      // {headerName: "Coder", field: "coderusername", tooltipField:'coderusername', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:150},
      // {headerName: "Claims Status", field: "claims_status", tooltipField:'claims_status', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:150},
      {
      headerName: "Payment",
      children: [    
      {headerName: "Status", field: "paymentStatus", tooltipField:'paymentStatus', sortable:true, resizable:true, filter:true, enableRowGroup:true, valueFormatter: (params) => capitalizeFirstChar(params.value), enablePivot:true, enableValue:true, width:140},
      {headerName: "Status Date", field: "paymentStatusDate", tooltipField:'paymentStatusDate', sortable:true, resizable:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:100, valueFormatter:(params)=>moment(params.value).format('MM/DD/YYYY'), filter: 'agDateColumnFilter', filterParams: filterParams},
      {headerName: "Amount", field: "paymentAmount", tooltipField:'paymentAmount', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:90},
      // {headerName: "Payment File", field: "paymentFile", tooltipField:'paymentFile', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:180},
      {headerName: "ACH", field: "ach", tooltipField:'ach', sortable:true, resizable:true, filter:true, enableRowGroup:true, enablePivot:true, enableValue:true, width:140},
      ]}
          
    ],
    frameworkComponents: {
      customLoadingOverlay: CustomLoadingOverlay,
      customNoRowsOverlay: CustomNoRowsOverlay,
    },
    loadingOverlayComponent: "customLoadingOverlay",
    noRowsOverlayComponent: "customNoRowsOverlay",
        defaultColDef:{ suppressHeaderFilterButton: true, type: 'disableTreeList' },
        columnTypes:{
            disableTreeList: {
              filterParams: { treeList: false },
            },
          }
  };

  componentDidMount() {
    console.log(this.props.providertins)
    fetch(`${localStorage.getItem("apiUrl")}/getPayments?providertins=${this.props.providertins}`
    )
      .then((res) => res.json())
      .then((res) => {
        let forReturn = [...res.rows];
        console.log(forReturn)
        this.setState(
          { rowData: forReturn }
        );
        // this.selectFirst()
        if (forReturn.length === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      })
      .catch(console.log);
  }

  // selectFirst=()=>{
  //   let totalRows = this.gridApi.getDisplayedRowCount()
  //   let selectIndex = 0
  //   if(totalRows == 0) {
  //     this.gridApi.deselectAll()
  //   } else {
  //     this.gridApi.deselectAll()
  //     this.gridApi.forEachNodeAfterFilterAndSort((node)=>{
  //       node.setSelected(node.rowIndex===selectIndex,true)
  //     })
  //     this.gridApi.paginationGoToPage(0);
  //   }
  // }

  selectPayment = (e) => {
    console.log(this.gridApi.getSelectedNodes()[0])
    // this.props.selectPaymentHandler(this.gridApi.getSelectedNodes()[0].data);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  };

  onFilterChanged=()=>{
    console.log('onFilterChanged event')
    // this.selectFirst()
  }

  rowClickedHandler = (e) => {
    console.log('rowClicked')
    if(this.props.editenabled) {
      if(window.confirm('Are you sure you want to leave the Edit mode? Any changes will be lost.')) {
        this.props.exitEdit()  // get out of edit mode
        e.node.setSelected(true) // Go to the clicked row
      }
    }
  }

  render() {
    return (
      <AgGridReact
        onGridReady={this.onGridReady}
      //  rowSelection="single"
        rowSelection={{mode:"singleRow", checkboxes:false, headerCheckbox:false, enableClickSelection:true, "copySelectedRows":false}}
        onSelectionChanged={this.selectPayment}
        onRowClicked = {this.rowClickedHandler}
        onFilterChanged={this.onFilterChanged}
        pagination={true}
        paginationAutoPageSize={true}
        columnDefs={this.state.columnDefs}
        rowData={this.state.rowData}
        groupHeaderHeight={20}
        headerHeight={45}
        rowHeight={20}
        enableCellTextSelection={true}
      //  suppressCopyRowsToClipboard={true}
        suppressCsvExport={true}
        suppressExcelExport={true}
      //  reactiveCustomComponents={true}
        components={this.state.frameworkComponents}
        loadingOverlayComponent={this.state.loadingOverlayComponent}
        noRowsOverlayComponent={this.state.noRowsOverlayComponent}
        defaultColDef={this.state.defaultColDef}
        columnTypes={this.state.columnTypes}
      ></AgGridReact>
    );
  }
}

export default PaymentResults;
