import React, { Component } from 'react';
import {formatDateTime} from '../../utils/layoutUtils'
import {Paper, TextField} from '@mui/material';
import { Container, InputGroup} from 'react-bootstrap';
import _ from 'lodash'
import localStorage from "react-secure-storage";
import '../Spinners.css'
/**
 * @class Users
 * @descr Display the Users
 * @version 1.0
 * @param none
 * @returns a grid of Users and Info
 */

const textStyle={ paddingTop: 0, paddingBottom: 5};

const inputText = {height:'29px',width:'89px', fontWeight:'bold'};

class MessageResponses2 extends Component {
    state = {
        rowData:[],
        loading: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.responseVersion!==this.props.responseVersion && this.props.id) { //then this updated
            this.callAPIRead()
        }
    }

    callAPIRead = () =>{
        console.log(this.props)
        this.setState({loading: true})
        fetch(localStorage.getItem('apiUrl')+`/getResponses?id=${this.props.id}`
    )
        .then(res=>res.json())
        .then(res=>{
            let forReturn=[...res.rows]
            forReturn = forReturn[0].responses
            forReturn = _.sortBy(forReturn,'dateTime').reverse()
            this.setState({rowData:forReturn, loading: false})
        })
        .catch( error => {
            console.log( error );
        });
    }
 

    render() {
        return (
            <Container fluid style={{height:this.props.height, overflow: 'auto',marginLeft: 10}}>
                {this.state.loading?
                    // <div id="overlay" style={{display: 'block'}}>
                        <div id="text">
                            <img className="loading" src={process.env.PUBLIC_URL + "/jadeMPA.png"} alt=" " />
                        </div>
                    // </div>
                  : <>
            {this.state.rowData.map((data) => (
            <Paper elevation={3} style={{width: '100%', marginBottom: 10, padding: 5}}>
                <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text size="sm" style={inputText}>From:</InputGroup.Text>
                </InputGroup.Prepend>
                <TextField style={{width: '88.9%', marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={localStorage.getItem('displayName') === data.fromName ? data.fromName + " <" + data.fromId + ">" : "Outreach Operations <system>" }></TextField>
                </InputGroup>
                <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text size="sm" style={inputText}>Date:</InputGroup.Text>
                </InputGroup.Prepend>
                <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={formatDateTime(data.dateTime)}></TextField>
                </InputGroup>
                <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text size="sm" style={inputText}>To:</InputGroup.Text>
                </InputGroup.Prepend>
                <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={data.toName + " <" + data.toId + ">"}></TextField>
                </InputGroup>
                <TextField style={{width: '100%', marginTop: 10}} multiline inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={data.message}></TextField>
            </Paper>
            ))
            }
            {JSON.stringify(this.props.original) !== '[]' ?
            <Paper elevation={3} style={{width: '100%', marginBottom: 10, padding: 5}}>
            <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text size="sm" style={inputText}>From:</InputGroup.Text>
            </InputGroup.Prepend>
            <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.props.original.fromName + " <" + this.props.original.fromId + ">"}></TextField>
            </InputGroup>
            <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text size="sm" style={inputText}>Date:</InputGroup.Text>
            </InputGroup.Prepend>
            <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={formatDateTime(this.props.original.dateTime)}></TextField>
            </InputGroup>
            <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text size="sm" style={inputText}>To:</InputGroup.Text>
            </InputGroup.Prepend>
            <TextField style={{width: '88.9%',marginTop: 5}} inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.props.original.toName + " <" + this.props.original.toId + ">"}></TextField>
            </InputGroup>
            <TextField style={{width: '100%', marginTop: 10}} multiline inputProps={{style: textStyle, readOnly: true}} variant='outlined' value={this.props.original.message}></TextField>
        </Paper>
            : null
            }
            </>
            }
           </Container>
        );
    }
}

export default MessageResponses2
