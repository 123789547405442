import React from "react";
import "./UB04.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import Popover from "react-bootstrap/Popover";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import localStorage from "react-secure-storage";

const regexTests = {
  numeric: { testCase: /^\d+$/, replace: /[^0-9]/g },
  alphaNumeric: { testCase: /^[a-zA-Z0-9]+$/, replace: /[^a-zA-Z0-9]/g },
  alpha: { testCase: /^[a-zA-Z]+$/, replace: /[^a-zA-Z]/g },
  anything: { testCase: /^.*/, replace: null },
  unit: {
    testCase: /^\d+(\.\d{0,3})?$/,
    replace: /[^0-9]|\.(?=.*\.)/g,
    replace2: /(\.\d{3}).*$/,
  },
};

const validateRegex = (e, regex) => {
  if (!regex.testCase.test(e.target.value)) {
    if (regex.replace) {
      e.target.value = e.target.value.replace(regex.replace, "");
    }
    if (regex.replace2) {
      e.target.value = e.target.value.replace(regex.replace2, "");
    }
  }
};

const TextInput = (props) => {
  const {
    name,
    size,
    prompt,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    isBlank,
    validate,
    regex,
  } = props;

  return (
    <BasicFieldWrapper
      name={name}
      activeField={activeField}
      isBlank={isBlank}
      validate={validate ? validate : null}
    >
      <input
        className="input"
        size={size}
        prompt={prompt}
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
        onInput={(e) => validateRegex(e, regex || regexTests.anything)}
      />
    </BasicFieldWrapper>
  );
};

const TextInputValue = (props) => {
  const { inputStyle, allCodes, code } = props;
  // console.log(props, "yoooooo");
  let tempCode = code;
  if (tempCode) {
    if (tempCode[0] == "0") {
      tempCode = tempCode.substring(1);
    }
  }
  let found = allCodes.find((ele) => ele.code.trim() === tempCode);
  let value = "";
  if (found) {
    value = found.longDesc;
  }
  // console.log(tempCode);
  // console.log("yo heres your value bro", found);
  return (
    <input
      className="input"
      readonly
      value={value}
      style={inputStyle ? inputStyle : undefined}
    />
  );
};

const DateInput = (props) => {
  const {
    name,
    maxLength,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    inputStyle,
    isBlank,
    validate,
  } = props;
  return (
    <BasicFieldWrapper
      name={name} // DATE NAME CHANGED
      activeField={activeField}
      isBlank={isBlank}
      validate={validate ? validate : undefined}
    >
      <input
        onFocus={(e) => handleFocus(e, setFieldTouched)}
        maxLength={maxLength}
        type="text"
        className="input center dateInput"
        disabled={disabled}
        style={inputStyle ? inputStyle : undefined}
      />
    </BasicFieldWrapper>
  );
};

const regex = {
  numeric: /^\d+$/,
  alphaNumberic: /^[a-zA-Z0-9]+$/,
  alpha: /^[a-zA-Z]+$/,
  dollars: /^\d+$/,
  cents: /^\d{1,2}$/,
  anything: /^.*/,
};

const validateDate = (date, greaterThanDate, lessThanDate, required) => {
  let error;
  if (required) {
    if (date?.length < 1 || !date) {
      error = "Required";
      return error;
    }
  }

  let dateVal = moment(date);

  if (greaterThanDate) {
    let minDate = moment(greaterThanDate);
    let diff2 = dateVal.diff(minDate);
    if (diff2 < 0) {
      error = `Date must be after ${minDate.format("MM/DD/YYYY")}`;
      return error;
    }
  }

  if (lessThanDate) {
    let maxDate = moment(lessThanDate);
    let diff2 = dateVal.diff(maxDate);
    if (diff2 > 0) {
      error = `Date must be before ${maxDate.format("MM/DD/YYYY")}`;
      return error;
    }
  }

  return error;
};

const DateInput2 = (props) => {
  const {
    name,
    handleFocus,
    setFieldTouched,
    activeField,
    disabled,
    isBlank,
    validate,
    dateFormat,
    className,
    setFieldValue,
  } = props;

  return (
    <BasicFieldWrapper
      name={name} // DATE NAME CHANGED
      activeField={activeField}
      validate={validate}
      isBlank={isBlank}
    >
      <Field name={name}>
        {({ field, form }) => (
          <DatePicker
            name={field.name}
            onFocus={(e) => handleFocus(e, setFieldTouched)}
            selected={field.value ? new Date(field.value) : null}
            onChange={(date) => setFieldValue(name, date)}
            disabled={disabled}
            dateFormat={dateFormat || "MM dd yy"}
            className={className || "ub04DateInput"}
            showYearDropdown
            scrollableMonthYearDropdown
            showMonthDropdown
          />
        )}
      </Field>
    </BasicFieldWrapper>
  );
};

const BasicFieldWrapper = (props) => {
  const {
    name,
    activeField,
    validate,
    description,
    id,
    children,
    wrapperStyles,
    isBlank,
  } = props;
  if (isBlank) {
    return (
      <div className="rowContainer" style={wrapperStyles}>
        {children}
      </div>
    );
  } else {
    return (
      <Field
        name={name}
        validate={validate ? validate : undefined}
        id={id ? id : undefined}
      >
        {({ field, form, meta }) => (
          <ErrorAndDescriptionWrapper
            name={field.name}
            error={meta.error}
            activeField={activeField}
            description={description}
          >
            <div className="rowContainer" style={wrapperStyles}>
              {React.cloneElement(children, { ...field })}
              {meta.error && (
                <ExclamationTriangleFill
                  color={"var(--optum-error-state-red)"}
                  size={12}
                />
              )}
            </div>
          </ErrorAndDescriptionWrapper>
        )}
      </Field>
    );
  }
};

const ErrorAndDescriptionWrapper = (props) => {
  const { error, description, name, activeField, children } = props;
  return (
    <ConditionalWrapper
      condition={error || description?.title}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          show={activeField === name}
          overlay={getPopoverStyling(error, description)}
        >
          {children}
        </OverlayTrigger>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children, descriptions }) =>
  condition ? wrapper(children, descriptions) : children;

const getPopoverStyling = (error, description) => {
  if (error)
    return (
      <Popover className="popoverError">
        <Popover.Content>{error}</Popover.Content>
      </Popover>
    );
  else if (description?.title)
    return (
      <Popover>
        <Popover.Title as="h3">{description.title}</Popover.Title>
        <Popover.Content>{description.long}</Popover.Content>
      </Popover>
    );
};

// const validateRevCd = (value) => {
//   let error;
//   if (value?.length < 1 || !value) {
//     error = "Required";
//   }

//   return error;
// };

let allCodes = [];

const validateCode = async (code, type, required, serviceDate) => {
  let error;
  if (required) {
    if (code?.length < 1 || !code) {
      return "Required";
    }
  }

  try {
    if (code[0] == "0") {
      code = code.substring(1);
    }
    let found = allCodes.find(
      (ele) =>
        ele.code.trim() === code && (ele.type === type || ele.type == "CPT")
    );
    if (!found) {
      let res = await fetch(
        // "http://localhost/api/v1.0" +
        localStorage.getItem("apiUrl") +
          `/getValidateCode?codeType=${type}&searchText=${code}&searchTypeFullText=1`
      );

      let resj = await res.json();
      if (resj.rows.length > 0) {
        for (let x = 0; x < resj.rows.length; x++) {
          allCodes.push({ ...resj.rows[x], success: true, type: type });
        }
        let found2 = resj.rows.find((ele) => ele.code.trim() === code);
        if (!found2) {
          allCodes.push({ code: code, success: false, type: type });
          return `Invalid Code`;
        } else {
          // if (new Date(found2.dtExpired) < new Date(serviceDate)) {
          //   return `Invalid ${type} Code`;
          // }

          if (found2.longDesc) {
            if (found2.longDesc.includes("Deleted code")) {
              return "Invalid Code";
            }
          }
        }
      } else {
        let res2 = await fetch(
          // "http://localhost/api/v1.0" +
          localStorage.getItem("apiUrl") +
            `/getValidateCode?codeType=${"CPT"}&searchText=${code}&searchTypeFullText=1`
        );
        let resj2 = await res2.json();
        if (resj2.rows.length > 0) {
          for (let x = 0; x < resj2.rows.length; x++) {
            allCodes.push({ ...resj2.rows[x], success: true, type: "CPT" });
          }
          let found2 = resj2.rows.find((ele) => ele.code.trim() === code);
          if (!found2) {
            allCodes.push({ code: code, success: false, type: "CPT" });
            return `Invalid Code`;
          } else {
            // if (new Date(found2.dtExpired) < new Date(serviceDate)) {
            //   return `Invalid ${type} Code`;
            // }

            if (found2.longDesc) {
              if (found2.longDesc.includes("Deleted code")) {
                return "Invalid Code";
              }
            }
          }
        } else {
          allCodes.push({ code: code, success: false, type: type });
          error = `Invalid Code`;
          return error;
        }
      }
    } else {
      if (!found.success) {
        return `Invalid Code`;
      }

      if (found.longDesc) {
        if (found.longDesc.includes("Deleted code")) {
          return "Invalid Code";
        }
      }
      // if (found.dtExpired) {
      //   if (new Date(found.dtExpired) < new Date(serviceDate)) {
      //     return `Invalid ${type} Code`;
      //   }
      // }
    }

    return error;
  } catch (err) {
    console.log(err);
  }
};

const validateHcpcs = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const validateServiceDate = (value) => {
  let error;
  if (value?.length < 1 || !value) {
    error = "Required";
  }

  return error;
};

const validateUnits = (value) => {
  let error;
  let val2 = value ? value.toString() : "";
  if (val2.length < 1 || !val2) {
    error = "Required";
  }

  if (val2.includes(".")) {
    let arr = val2.split(".");
    if (arr[0].length > 8) {
      error = "Maximum length to the left of decimal is 8";
    }
    if (arr[1].length > 3) {
      error = "Maximum length to the right of the decimal is 3";
    }
  } else {
    if (val2.length > 8) {
      error = "Maximum length is 8";
    }
  }

  return error;
};

// const validateCharge = (value) => {
//   let error;
//   if (value?.length < 1 || !value) {
//     error = "Required";
//   }

//   return error;
// };

const validateCharge = async (value, type) => {
  // "payerName.payerId.releaseOfInformation.assignmentOfBenefits.priorPaymentsDollars.priorPaymentsCents.estimatedAmtDueDollars.estimatedAmtDueCents.otherProvider"
  let error;
  if (value?.length < 1 || !value) {
    return "Required";
  }

  if (type == "dollars") {
    if (isNaN(value)) {
      return "Invalid dollar amount";
    } else if (value.includes(".")) {
      return "Remove decimal";
    }
  }

  if (type == "cents") {
    if (value?.length < 2) {
      return "Minimum length is 2";
    } else if (isNaN(value)) {
      return "Invalid cents amount";
    } else if (value.includes(".")) {
      return "Remove decimal";
    }
  }

  return error;
};

const InvoiceLine = (props) => {
  const {
    rowNo,
    handleFocus,
    setFieldTouched,
    activeField,
    isBlank,
    currentPage,
    setFieldValue,
    adjustedClaim,
  } = props;
  const space =
    (currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22) < 10 ? (
      <span>&thinsp;</span>
    ) : (
      ""
    );
  let ID;
  if (rowNo % 2 == 0) {
    ID = "ub04rowEven";
  } else {
    ID = "ub04rowOdd";
  }

  return (
    <tr id={ID}>
      <td
        colSpan={3}
        style={{ border: "none", background: "none" }}
        className="boldSmallLRightAlighned"
      >
        {currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22}
        {space}
      </td>
      <td colSpan={14}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].revenueCode`}
          number={`revenueCode${rowNo - 1}`}
          title="Revenue Code"
          subtitle="Revenue Code"
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          validate={(val) =>
            validateCode(
              val,
              "Revenue",
              true,
              adjustedClaim.serviceLines[rowNo - 1].serviceDate
            )
          }
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
          isBlank={isBlank}
        />
      </td>
      <td colSpan={76}>
        <TextInputValue
          title="Description"
          subtitle="Description"
          allCodes={allCodes}
          code={adjustedClaim.serviceLines[rowNo - 1]?.revenueCode}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={45}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].hcpcsRateHippsCode`}
          number={`hcpcsRateHippsCode${rowNo - 1}`}
          title="HCPCS Rate Hipps Code"
          subtitle="HCPCS Rate Hipps Code"
          validate={(val) =>
            validateCode(
              val,
              "HCPC",
              true,
              adjustedClaim.serviceLines[rowNo - 1].serviceDate
            )
          }
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
          isBlank={isBlank}
        />
      </td>
      <td colSpan={21}>
        <DateInput2
          name={`serviceLines[${rowNo - 1}].serviceDate`}
          maxLength={8}
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          validate={(val) =>
            validateDate(
              val,
              adjustedClaim.admitDate,
              adjustedClaim.dischargeDate,
              true
            )
          }
          setFieldValue={setFieldValue}
          dateFormat="MM dd yy"
          className={ID === "ub04rowEven" ? "ub04DateInput2" : "ub04DateInput"}
        ></DateInput2>
      </td>
      <td colSpan={24}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].unitCount`}
          number={`unitCount${rowNo}`}
          title={`Unit Count`}
          subtitle="Unit Count"
          validate={validateUnits}
          regex={regexTests.unit}
          maxLength={12}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={22} style={{ borderRight: "1px dotted #00000036" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].chargesDollars`}
          number={`chargesDollars${rowNo}`}
          title={`Total Charges Dollars`}
          subtitle="Total Charges Dollars"
          validate={(val) => validateCharge(val, "dollars")}
          regex={regexTests.numeric}
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven"
              ? { backgroundColor: "#e0e0e0", textAlign: "right" }
              : { textAlign: "right" }
          }
        />
      </td>
      <td colSpan={7} style={{ borderLeft: "none" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].chargesCents`}
          number={`chargesCents${rowNo}`}
          title={`Total Charges Cents`}
          regex={regexTests.numeric}
          subtitle="Total Charges Cents"
          validate={(val) => validateCharge(val, "cents")}
          maxLength={2}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={22} style={{ borderRight: "1px dotted #00000036" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].nonCoveredChargesDollars`}
          number={`nonCoveredChargesDollars${rowNo}`}
          title={`Non Covered Charges Dollars`}
          subtitle="Non Covered Charges Dollars"
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          validate={(val) => validateCharge(val, "dollars")}
          regex={regexTests.numeric}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven"
              ? { backgroundColor: "#e0e0e0", textAlign: "right" }
              : { textAlign: "right" }
          }
        />
      </td>
      <td colSpan={7} style={{ borderLeft: "none" }}>
        <TextInput
          name={`serviceLines[${rowNo - 1}].nonCoveredChargesCents`}
          number={`nonCoveredChargesCents${rowNo}`}
          title={`Non Covered Charges Cents`}
          subtitle="Non Covered Charges Cents"
          maxLength={2}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          regex={regexTests.numeric}
          validate={(val) => validateCharge(val, "cents")}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td colSpan={8}>
        <TextInput
          name={`49-${rowNo}`}
          number={`49-${rowNo}`}
          title={`49-${rowNo}`}
          subtitle=""
          maxLength={30}
          size="30"
          handleFocus={handleFocus}
          setFieldTouched={setFieldTouched}
          activeField={activeField}
          disabled={false}
          isBlank={isBlank}
          inputStyle={
            ID === "ub04rowEven" ? { backgroundColor: "#e0e0e0" } : null
          }
        />
      </td>
      <td
        colSpan={3}
        style={{ border: "none", background: "none" }}
        className="boldSmallLeftAlighned"
      >
        {space}
        {currentPage == 1 ? rowNo : rowNo - (currentPage - 1) * 22}
      </td>
    </tr>
  );
};

export default InvoiceLine;
