import React, { Component } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import {formatDateTime} from '../../utils/layoutUtils'
import CustomLoadingOverlay from '../../components/customLoadingOverlay.jsx';
import CustomNoRowsOverlay from '../../components/customNoRowsOverlay.jsx';
import _ from 'lodash'
import localStorage from "react-secure-storage";

/**
 * @class Users
 * @descr Display the Users
 * @version 1.0
 * @param none
 * @returns a grid of Users and Info
 */

const blueStyle = {display:'inline-block',padding:'.25em .4em',fontSize:'90%',fontWeight:'700',lineHeight:'2', textAlign:'center',whiteSpace:'nowrap',verticalAlign:'baseline',
    borderRadius:'.25rem', color:'#ffffff',backgroundColor:'#17a2b8', paddingRight:'.6em',paddingLeft:'.6em', position:'relative',top:'-1px'
}

const greenStyle = {display:'inline-block',padding:'.25em .4em',fontSize:'90%',fontWeight:'700',lineHeight:'2', textAlign:'center',whiteSpace:'nowrap',verticalAlign:'baseline',
    borderRadius:'.25rem', color:'#ffffff',backgroundColor:'#28a745', paddingRight:'.6em',paddingLeft:'.6em', position:'relative',top:'-1px'
}

const redStyle = {display:'inline-block',padding:'.25em .4em',fontSize:'90%',fontWeight:'700',lineHeight:'2', textAlign:'center',whiteSpace:'nowrap',verticalAlign:'baseline',
    borderRadius:'.25rem', color:'#ffffff',backgroundColor:'#dc3545', paddingRight:'.6em',paddingLeft:'.6em', position:'relative',top:'-1px'
}

class Users extends Component {
    state = {
        users: [],
        columnDefs:[
            {headerName: "Date/Time", field: "dateTime", sortable:true, resizable:true, filter:true, width:170, valueFormatter:(params) => formatDateTime(params.value)}, 
            {headerName: "_id", hide:true, field: "_id", sortable:true, resizable:true, filter:true, width:150}, 
            {headerName: "From ID", hide:true, field: "fromId", sortable:true, resizable:true, filter:true, width:140},
            {headerName: "From Name", hide:true, field: "fromName", sortable:true, resizable:true, filter:true, width:150},
            // {headerName: "From Email", hide:true, field: "fromEmail", sortable:true, resizable:true, filter:true, width:130},
            {headerName: "Subject", field: "subject", sortable:true, resizable:true, filter:true, width:300},
            // {headerName: "Internal/External", field: "intExt", sortable:true, resizable:true, filter:true, width:140, valueFormatter:(params) => {return params.value == 'I' ? 'Internal' : 'External'} },
            {headerName: "Status", field: "status", cellClass: "centered-cell", sortable:true, resizable:true, filter:true, width:150, cellRenderer:(params) => {return this.customStatusHandler(params)}},
            {headerName: "Replies", field: "replies", sortable:true, resizable:true, filter:true, width:90},
            // {headerName: "Action", field: "action", sortable:true, resizable:true, filter:true, width:100, cellRenderer:(params)=>{return (<p name={params.value} title={params.value} style={{color: 'DarkOrange', fontWeight:'bold', cursor: 'pointer', background:'none', border:'none',padding:'0px'}}> Reply</p>)}},
        ],
        rowData:null,
        frameworkComponents: {
            customLoadingOverlay: CustomLoadingOverlay,
            customNoRowsOverlay: CustomNoRowsOverlay,
            },
            loadingOverlayComponent: 'customLoadingOverlay',
            noRowsOverlayComponent: 'customNoRowsOverlay',
        defaultColDef:{ suppressHeaderFilterButton: true, type: 'disableTreeList' },
        columnTypes:{
            disableTreeList: {
              filterParams: { treeList: false },
            },
          }
    }

    componentDidMount () {
        // console.log( this.props );
        this.callAPIRead()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.inboxVersion!==this.props.inboxVersion) { //then this updated
        //    this.gridApi.showLoadingOverlay()
            this.gridApi.setGridOption("loading", true);
            this.callAPIRead()
            this.gridApi.hideOverlay()
        }
    }

    customStatusHandler = (node) => {
        console.log(node)
        let overallStatus = node.value
        let length = node.data.responses.length - 1
        let val = length === -1 ? null : node.data.responses[length]
        let intExt = val === null ? null : val.intExt
        let status =  val === null ? null : val.status
        console.log(overallStatus)
        if(overallStatus === 'Resolved') {
            return <span style={greenStyle}>Resolved</span>
        } else {
        if(intExt) {
            if(intExt === 'I') {
            switch(status) {
                case 'unread':
                    return <span  style={redStyle}>Awaiting Reply</span>
                default:
                    return <button>default</button>
               }
            } else {
                switch(status) {
                    case 'unread':
                        return <span  style={blueStyle}>Replied</span>
                    // case 'Replied':
                    //         return <span style={{ fontSize: 12, height: 20}} class="badge badge-pill badge-info">Replied</span>
                    case 'resolved':
                            return <span  style={greenStyle}>Resolved</span>
                    default:
                        return <button>default</button>
                }
            } 
        } else {
            return <span  style={greenStyle}>Sent</span>
              }
            
        }}

    callAPIRead = () =>{
        fetch( localStorage.getItem('apiUrl')+`/getOutboxMessages?fromId=${localStorage.getItem('userkey')}`
    )
        .then(res=>res.json())
        .then(res=>{
            let forReturn=[...res.rows]
            console.log(forReturn)
            forReturn.forEach(function(item) {
                item.replies = item.responses.length
                item.action='Reply'
            })
            forReturn = _.sortBy(forReturn,'dateTime').reverse()
            this.setState({rowData:forReturn})
        })
        .catch( error => {
            console.log( error );
        });
    }

    selectedRowChangedHandler = () => {
        let selectedRows = this.gridApi.getSelectedRows()
        // this.setState({userkey:selectedRows[0].userkey})
        if (selectedRows && selectedRows.length>0) {
            this.props.changeMessage(selectedRows[0])
            // console.log(selectedRows[0])
        } else {
            this.props.changeMessage(null)
        }
    }

    clearFilter = () => {
        if(this.gridApi)
        {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        }
    }

    cellClickedHandler = (event) => {
        if(event.colDef.field==='action' && event.value)
        {
            console.log(event)
            this.props.replyClicked(event.node.data._id)
            return
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    //    this.gridApi.showLoadingOverlay();
        this.gridApi.setGridOption("loading", true);
    } 

    render() {
        return (
            <div className="ag-theme-balham" style={{height:this.props.height, width:'100%'}}>
                <AgGridReact
                    onGridReady={this.onGridReady}
                //    rowSelection='single'
                    rowSelection={{mode:"singleRow", checkboxes:false, headerCheckbox:false, enableClickSelection:true}}
                    onSelectionChanged={this.selectedRowChangedHandler}
                    onCellClicked = {this.cellClickedHandler}
                    pagination={true}
                    paginationAutoPageSize={true}
                    suppressCellSelection={true}
                    autoSize={true}
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.rowData}
                    enableCellTextSelection={true}
                    components={this.state.frameworkComponents}
                //    reactiveCustomComponents={true}
                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                    noRowsOverlayComponent={this.state.noRowsOverlayComponent}
                    defaultColDef={this.state.defaultColDef}
                    columnTypes={this.state.columnTypes}
                ></AgGridReact>
            </div>
        );
    }
}

export default Users;