import React, { Component } from "react";
// import logo from './logo.svg';
import "./App.css";
import NavBarTop from "./components/NavBarTop";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import RouteSwitch from "./components/RouteSwitch";
import { BrowserRouter } from "react-router-dom";
// import Login from "./Login";
import Auth from './Auth'
import pjson from "../package.json";
import moment from "moment-timezone";
import Oops from './Oops'
import Oops2 from './Oops2';
import TOU from './components/TOU'
import Login from "./Login";
// import { render } from 'node-sass';
// import {ReadableWebToNodeStream} from 'readable-web-to-node-stream'
// import fs from 'fs'
import Messaging from "./containers/Messaging/Messaging"
import DescriptionAlerts from "./components/DescriptionAlert";
import FederalAttestation from "./components/FederalAttestation";
import LoginError from "./LoginError";
import MaintenenaceMessage from "./MaintenenaceMessage"
import localStorage from "react-secure-storage";
// import { generateToken } from "./utils/layoutUtils";
var originalFetch = window.fetch;
window.fetch = async function (input, init) {
    if(input == `${process.env.REACT_APP_AUTH_URL}/dms/aaa`) {
      return originalFetch(input, init);
    }

    if(input.includes(`${process.env.REACT_APP_PROXY_URL}/getUserFromCode`)) { 
      if (!init) {
        init = {};
    }
    if (!init.headers) {
        init.headers = new Headers();
    }
    const token = "12345678910"
   
    if (init.headers instanceof Headers) {
        init.headers.append('Authorization', `Bearer ${token}`);
    } else if (init.headers instanceof Array) {
        init.headers.push(['Authorization', `Bearer ${token}`]);
    } else {
        // object ?
        init.headers['Authorization'] = `Bearer ${token}`;
    }
      
      return originalFetch(input, init);
    }
    if (!init) {
        init = {};
    }
    if (!init.headers) {
        init.headers = new Headers();
    }
    const token = localStorage.getItem("token")
   
    if (init.headers instanceof Headers) {
        init.headers.append('Authorization', `Bearer ${token}`);
    } else if (init.headers instanceof Array) {
        init.headers.push(['Authorization', `Bearer ${token}`]);
    } else {
        // object ?
        init.headers['Authorization'] = `Bearer ${token}`;
    }
    return originalFetch(input, init);
};

class App extends Component {
  state = {
    auth: localStorage.getItem('auth') && localStorage.getItem('auth')==='true'?true:false,//localStorage.getItem("auth") ? localStorage.getItem("auth") : 
    userDetails:null,
    environment: process.env.REACT_APP_ENV||"Dev",
    oops:false,
    oops2:false,
    tou:false,
    touAccepted:localStorage.getItem('touAccepted') && localStorage.getItem('touAccepted')==='true'?true:false,
    fedAttAccepted: localStorage.getItem('fedAttAccepted') && localStorage.getItem('fedAttAccepted')==='true'? true:false,
    fedAtt: localStorage.getItem('fedAttAccepted') && localStorage.getItem('fedAttAccepted')==='true'?false:true,
    hardCoding:false,
    showMessages : false,
    showAlerts: false,
    messageCount: 0,
    alertCount: 0,
    showError: false,
    navVersion: 0,
    showMaintenance: false,
  };

  componentDidMount() {
    localStorage.setItem('readMessage','0')
    localStorage.setItem('readAlert','0')
    //test
    // this.loadMessageCount()
    // setInterval(this.loadMessageCount, 30000);
    if (moment().isAfter(localStorage.getItem('loginExpiration'))) {
        localStorage.clear()
        window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + '&prompt=login')
    } 
  }

  loadMessageCount = async () => {
    const res = await fetch(localStorage.getItem('apiUrl')+`/getInternalUnreadCount?fromId=${localStorage.getItem('userkey')}`);
    const resj = await res.json();
    const res1 = await fetch(localStorage.getItem('apiUrl')+`/getAlertsCount`);
    const resj1 = await res1.json();
    if(localStorage.getItem('readMessage') === '0' && localStorage.getItem('readAlert') === '0') {
    this.setState({
      messageCount: resj.unreadCount,
      alertCount: resj1.alertCount
    })
    } else {
      if(resj.unreadCount !== this.state.messageCount) {
        localStorage.setItem('readMessage','0')
        this.setState({
          messageCount: resj.unreadCount
        })
      }
      if(resj1.alertCount !== this.state.alertCount) {
        localStorage.setItem('readAlert','0')
        this.setState({
          alertCount: resj1.alertCount
        })
    }
    }

}

  componentWillUnmount() {
  localStorage.setItem('readMessage','0')
  localStorage.setItem('readAlert','0')
  }

  postRemoveUserOk = (u) => {
    let forPosting = {
        "u" : u,
        "dateTime": moment(new Date()).tz('America/Los_Angeles').format("YYYY-MM-DD[T]HH:mm:ss")
    }

    fetch(
        `${process.env.REACT_APP_API_URL}/removeUserOk`,//|| "http://apsrp07890.uhc.com:8888"
        {
            method: "post",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(forPosting),
        },
    )
    .then((res) => {
    })
    .catch(console.log);
}

  setSettings = async(data, hardCoding)=>{
    console.log(data)
    if ((data.validTinsCount<1 && !hardCoding)||!data.active) { //send to oops 
        //clear storage then oops
        // localStorage.clear()
        // window.location.assign('/oops')
        this.setState({oops:true})
    } else if(data.validTinsCount>0 && data.validTinsWithPackageCount<1  && !hardCoding) { //send to second ooops 
        // localStorage.clear()
        this.setState({oops2:true})
    } else if(hardCoding || data.validTinsWithPackageCount>0){
        localStorage.setItem('authInfo', JSON.stringify(data))
        // JSON.stringify(data.auth)
        // localStorage.setItem('user', JSON.stringify(data.user))
        localStorage.setItem("environment", this.state.environment);
        localStorage.setItem("apiUrl", process.env.REACT_APP_API_URL);
        localStorage.setItem("nxUrl", process.env.REACT_APP_NX_URL||"https://nuxeodev.optum.com");
        localStorage.setItem("navHeader", this.state.environment==="Prod"?"":this.state.environment);
        // localStorage.setItem("auth", data.user.email_verified);
        localStorage.setItem("displayName", data.user.name);
        localStorage.setItem("u", data.user.preferred_username);
        
        localStorage.setItem("userkey", data.user.preferred_username);
        localStorage.setItem('email', data.user.email)
        localStorage.setItem('clients', data.clients.join(','))
        let tins=data.pcrValidTins && data.pcrValidTins.length>0?data.pcrValidTins.sort().join(','):'' //this is hardcoded if hardcoded is true
        if(hardCoding){
            tins=["112050523", "131740114", "133971298", "135562308", "201291415", "204716124", "204762421", "205198811", "232743545", "232919472", "251838458", "260834681", "262055034", "274531496", "310833936", "320117972", "340714585", "341790929", "390806395", "390817529", "461177336", "481216204", "481229965", "486341644", "522112078", "592951990", "620476282", "640507572", "741857100", "741941710", "742800601", "760460242", "760681269", "860224023", "860252067", "860285857", "860334996", "860930581", "900730402", "910214500", "911939739", "943282424", "990358233"].join(',')
        }
        let user=data.user
        user.tins=tins
        user.tinsCount=data.pcrValidTins.length
        user.clients=data.clients.sort().join(',')
        user.clientCount=data.clients.length
        user.markets=data.markets.sort().join(',')
        user.marketCount=data.markets.length

        // let markets = data.markets;
        // if(markets.includes('NY')) {
          // this.setState({nyAtt: true, nyAttAccepted: false})
        // }

        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem("tin", tins);//pcrValidTins
        //now get userPayEligibility
        localStorage.setItem('userPayEligible',JSON.stringify(data.userPayEligible))
        console.log(JSON.stringify(localStorage.getItem('user')))
        // This is now part of enrichJson so no need to make another call
        // fetch(`${localStorage.getItem('apiUrl')}/getUserPayEligibility?tins=${tins}`).then(res=>res.json())
        // .then(resj=>{
        //   console.log(resj)
        //   console.log(resj.userPayEligible)
        //   localStorage.setItem('userPayEligible',JSON.stringify(resj.userPayEligible))
        //   console.log(JSON.parse(localStorage.getItem('userPayEligible')))
        // })
        // .catch(console.log)
        
        localStorage.setItem(
        "loginExpiration",
         moment().add(process.env.REACT_APP_MAX_TIME, "hours")
        );
        
        localStorage.setItem("filter", 'adjusted')
        localStorage.setItem('qIndex', JSON.stringify(0))
        localStorage.setItem('active', true)
        console.log(pjson.description);
        console.log(pjson.version);
        localStorage.setItem("appDescription", pjson.description);
        localStorage.setItem("appVersion", pjson.version);
        console.log(
        localStorage.getItem("appDescription"),
        localStorage.getItem("appVersion")
        );
        // add usage statistic in here

        // localStorage.setItem('auth',true)
        // 6c Posting
        // let forPosting={
        //     "product": "JadeXPlus",
        //     "class": "SECURITY_SUCCESS",
        //     "destUserMSID": data.user.preferred_username,
        //     "destUserName":  data.user.name,
        //     "destUserPriv": "external user",
        //     "destUserRole": 'provider',
        //     "message": "UserLoginAuthentication:SUCCESS",
        //     "severity": "INFO"
        // }
        // fetch(
        //     `${localStorage.getItem("apiUrl") }/post6c`,//|| "http://apsrp07890.uhc.com:8888"
        //     {
        //         method: "post",
        //         headers: new Headers({ "Content-Type": "application/json" }),
        //         body: JSON.stringify(forPosting),
        //     }
        // )
        // // .then((res) => res.json())
        // .then((res) => {
        //     // console.log(res);
        //     // localStorage.clear();

        // })
        // .catch(console.log);
        // this.setState({auth:true, showTOU:true})
        // this.props.history.push({pathname:"/tou"}); //
        // this.props.history.push({pathname:"/dashboard", filter:'all'}); //
        // this.usageStatistic()
        // .then()
        // .catch(console.log)
        // Promise.allSettled([this.usageStatistic(),this.useStats(data)])
        // this.useStats(data)
        this.usageStatistic()
        .then(r=>{
          console.log(r)
          this.useStats(data)
          .then(res=>res.json())
          .then(resj=>{
            if (resj.foundUser) {
              localStorage.setItem('auth', true)
              localStorage.setItem('touAccepted',true)
              this.setState({auth:true, touAccepted:true, tou:false, fedAtt: true})
            } else {
              this.setState({touAccepted:false, tou:true, fedAtt: true})
            }
          })
          .catch(console.log)
        })
        .catch(console.log)



        this.postRemoveUserOk(data.user.preferred_username);
        

        let r = await fetch(localStorage.getItem("apiUrl") + `/getRatings?user=${localStorage.getItem("u")}&appName=Jade External PCR`);
        let rj = await r.json();
        if (rj && rj.rows && rj.rows.length > 0) {
            //already has entry in ratings
            console.log("already has entry in ratings");
            localStorage.setItem("appRating", JSON.stringify(rj.rows[0].rating));
        }
        
        // if (!this.state.touAccepted) {
        //   this.setState({tou:true})
        // } else {
        //   localStorage.setItem('auth', true)
        //   this.setState({auth:true})
        //   // window.location.assign('/dashboard')
        // }
        // this.loadMessageCount()
    }
  }

  usageStatistic = async()=>{
    try{
      let res=await fetch(`${localStorage.getItem("apiUrl") }/putUserStat`,
        {
          method:'post',
          headers: new Headers({ "Content-Type": "application/json" }),
          body: localStorage.getItem('user'),
        }
      )
      console.log('res', res); // Console logged var to remove no-unused-vars lint error
      return res
    } catch(e){
      console.log(e)
    }
  }

  useStats = async(data)=>{
    try {
      let forPosting={
        "product": "JadeXPlus",
        "class": "SECURITY_SUCCESS",
        "destUserMSID": data.user.preferred_username,
        "destUserName":  data.user.name,
        "destUserPriv": "external user",
        "destUserRole": 'provider',
        "message": "UserLoginAuthentication:SUCCESS",
        "severity": "INFO"
      }
      let post6cResult= await fetch(`${localStorage.getItem("apiUrl") }/post6c`,{
          method: "post",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(forPosting),
        },
    
      )
      console.log('post6cResult', post6cResult);  // Console logged var to remove no-unused-vars lint error
      let checkTou= await fetch(`${localStorage.getItem("apiUrl") }/getPriorUser?sub=${data.user.sub}`);
      return checkTou
    } catch(e){
      console.log(e)
    }
  }

  handleAuth = (data) => {
    console.log(data);
    if (data && data.user && data.user.email_verified) {
        this.setSettings(data, this.state.hardCoding)
    }
  };

  handleAcceptReject=(clicked)=>{
    if (clicked==='accept') {
      localStorage.setItem('touAccepted',true)
      this.setState({auth:true, touAccepted:true, tou:false})
      //post acceptance to db
      // fetch(`${localStorage.getItem("apiUrl") }/putUserStat`,
      //   {
      //     method:'put',
      //     headers: new Headers({ "Content-Type": "application/json" }),
      //     body: localStorage.getItem('user'),
      //   }
      // )
      // .then(res=>res.json())
      // .then(resj=>{
      //   console.log(resj)
      //   this.setState({auth:true, touAccepted:true, tou:false})
      // })
      // .catch(console.log)
      // window.location.assign('/dashboard')
      // window.location.reload()
    } else if (clicked==='privacyView'){
      window.open(`${process.env.PUBLIC_URL}/optum-pcr-privacy-policy.pdf`, 'PrivacyPolicyViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on') //PrivacyPolicyViewer
    } else if (clicked==='touView'){
      window.open(`${process.env.PUBLIC_URL}/optum-pcr-terms-of-use.pdf`, 'TermsOfUseViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on') //TermsOfUseViewer
    }
    else {
      let txtAlert=`It appears you have declined our Terms of Use and Privacy Policy. please contact your outreach operations representative at the number located in your package email or 1-866-985-8462`
      window.alert(txtAlert)
      localStorage.clear()
      window.location.assign('https://uhcprovider.com')
    }
    
}

handleAcceptRejectFederalAtt=()=>{
    try {
      localStorage.setItem('fedAttAccepted',true)
      this.setState({fedAtt: false, fedAttAccepted: true})    
    } catch(e){
      console.log(e)
    }
}

showMessagesHandler = () => {
  console.log(this.state.showMessages)
  localStorage.setItem('readMessage','1')
  this.setState({
    showMessages : this.state.showMessages ? false : true,
    // messageCount: this.state.messageCount + 1
  })
}

closeHandler = () => {
  localStorage.setItem('readMessage','1')
  console.log(this.state.showMessages)
  this.setState({
    showMessages : false
  })
}

renderMessages = () => {
  return (
  <Messaging showMessages={this.state.showMessages} closeHandler={this.closeHandler} loadMessageCount={this.loadMessageCount}></Messaging>
  )
}

showAlerts = () => {
  localStorage.setItem('readAlert','1')
  this.setState({showAlerts: true})
}

closeAlerts = () => {
  this.setState({showAlerts: false})
}

renderAlerts = () => {
  if(this.state.showAlerts) {
  return (
   <DescriptionAlerts closeAlerts={this.closeAlerts}></DescriptionAlerts>
  )
    }
}

showError= () => {
  this.setState({showError: true})
}

showBroadcastMessage= () => {
  this.setState({navVersion: this.state.navVersion + 1})
}

showMaintenance = () => {
  this.setState({showMaintenance: true})
}

handleSuccessfulAuth = (data) => {
  console.log(data);
  if (data.auth) {
    // JSON.stringify(data.auth)
    localStorage.setItem("auth", data.auth);
    localStorage.setItem("displayName", data.displayName);
    localStorage.setItem("u", data.u);
    localStorage.setItem("userkey", data.u);
    localStorage.setItem("tin", data.tin);
    localStorage.setItem(
      "loginExpiration",
      moment().add(8, "hours")
    );
    console.log(pjson.description);
    console.log(pjson.version);
    localStorage.setItem("appDescription", pjson.description);
    localStorage.setItem("appVersion", pjson.version);
    console.log(
      localStorage.getItem("appDescription"),
      localStorage.getItem("appVersion")
    );
    //this.setState({auth:true})
    //this.props.history.push('/dashboard')
  }
};


  render() {
    console.log(this.state.auth);
    let appIn = (
      <Container fluid>
        <NavBarTop showMessagesHandler={this.showMessagesHandler} messageCount={this.state.messageCount} alertCount={this.state.alertCount} showAlerts={this.showAlerts} navVersion={this.state.navVersion}/>
        {this.renderMessages()}
        {this.renderAlerts()}
        <Container fluid style={{ paddingTop: "58px", margin: "0px" }}>
          <Row>
            <Col>
              <RouteSwitch showBroadcastMessage={this.showBroadcastMessage} />
            </Col>
          </Row>
        </Container>
      </Container>
    );
    let showcase={
      appIn:appIn,
      auth:(<Auth handleAuth={this.handleAuth} showError={this.showError} showMaintenance={this.showMaintenance}/>),
      oops:(<Oops />),
      oops2:(<Oops2 />),
      tou:(<TOU handler={this.handleAcceptReject}/>),
      fedAtt:(<FederalAttestation handler={this.handleAcceptRejectFederalAtt}/>),
      loginError:(<LoginError></LoginError>),
      maintenance:(<MaintenenaceMessage></MaintenenaceMessage>),
      devLogin:(<Login></Login>),
      default:(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "" ? <Login handleAuth={this.handleSuccessfulAuth}></Login> : <Auth handleAuth={this.handleAuth} showError={this.showError} showMaintenance={this.showMaintenance}/>)
    }
    console.log(this.state.userDetails)
    let toShow //=showcase.default'
    if(this.state.showError) {
      toShow=showcase.loginError
    } else if (this.state.showMaintenance) {
      toShow=showcase.maintenance
    } else if (this.state.oops) {
      toShow=showcase.oops
    } else if (this.state.oops2) {
      toShow=showcase.oops2
    } else if (this.state.auth && this.state.fedAtt && !this.state.fedAttAccepted) {
      toShow=showcase.fedAtt
    } else if (this.state.tou && !this.state.touAccepted) {
      toShow=showcase.tou
    } else if (this.state.auth || (localStorage.getItem('auth') && localStorage.getItem('auth')==='true' && moment().isBefore(localStorage.getItem('loginExpiration')))) {
      toShow=showcase.appIn
    } 
    else {
      toShow=showcase.default
    }
    return (
      <BrowserRouter>
        {toShow}
      </BrowserRouter>
    );
  }
}

export default App;
